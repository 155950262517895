<template>
  <ValidationObserver ref="nbRnWizard">
    <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div class="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav" v-show="appData">
          <div class="wizard-steps">
            <div class="wizard-step" data-wizard-type="step" v-for="(wizardPage, index) in wizardPages" :key="index">
              <div class="wizard-wrapper">
                <div class="wizard-number">{{ wizardPage.id }}</div>
                <div class="wizard-label"><div class="wizard-title">{{ wizardPage.title }}</div>
                  <div class="wizard-desc">{{ wizardPage.subtitle }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-10">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!-- ERROR ALERTS -->
                  <b-alert v-if="this.errorAlert" variant="warning" fade show>
                    <div class="text-dark" v-html="errorAlert"></div>
                  </b-alert>

                  <!--begin: Wizard Step 1-->
                  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div v-if="appData">
                      <review-page
                          :app-data="appData" :comparison-data="comparisonData" :is-read-only="isWizardReadOnly"
                          :ibais-memo-number="ibaisMemoNumber" :loading-comparison-data="loadingComparisonData"
                          @acceptForm="checkClientAndAcceptForm" @voidForm="voidForm" @unvoidForm="unvoidForm"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="appData">
                      <inclusions-page
                          :app-data="appData" :is-read-only="isWizardReadOnly"
                          :selected-inclusions="selectedInclusions"
                          :filtered-inclusions="filteredInclusions"
                          @processInclusionsBasedOnBusinessActivities="processInclusions"
                          @onUpdate="updateInclusions"
                          @setInclusionsChanged="setInclusionsChanged"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="appData && referenceData">
                      <fleet-page
                          :app-data="appData" :ref-data="referenceData" :is-read-only="isWizardReadOnly"
                          @refreshFleetStatus="refreshFleetStatus"
                          @downloadTransactionFleet="downloadTransactionFleet"
                          @saveTransactionFleet="saveTransactionFleet"
                          @fleetUploadCompleted="uwFleetUploadCompleted"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="appData && referenceData">
                      <premium-page
                        :app-data="appData" :vehicle-types="referenceData.vehicleTypes" :is-read-only="isWizardReadOnly"
                        :expand-all-sections="premiumPageExpandAllSections"
                        @getVehicleSummaryFromFleetDeclaration="getVehicleSummaryFromFleetDeclaration"
                        @calculateVehiclePremiumTotals="calculateVehiclePremiumTotals"
                        @calculateStateSummaryTotals="calculateStateSummaryTotals"
                        @calculateBusinessActivityPercentTotal="calculateBusinessActivityPercentTotal"
                        @calculatePostcodeDistributionTotal="calculatePostcodeDistributionTotal"
                        @calculateNetIncurredTotals="calculateNetIncurredTotals"
                        @calculateTotalFees="calculateTotalFees"
                        @calculatePremiums="calculatePremiums"
                        @calculatePremiumTotalsUwOverride="calculatePremiumTotalsUwOverride"
                        @savePremiumPage="savePremiumPage"
                        @refreshClaimsHistory="refreshClaimsHistory"
                        @refreshUwApprovals="refreshUwApprovals"
                        @approveApproval="approveApproval"
                        @rejectApproval="rejectApproval"
                        @handleError="handleError"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <finalisation-page
                        :app-data="appData" :is-read-only="isWizardReadOnly" :ibais-memo-number="ibaisMemoNumber" :outcome="outcome"
                        @createQuoteDocument="createQuoteDocument"
                        @setOutcome="setOutcome"
                        @setMemoNumber="setMemoNumber"
                    />
                  </div>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-prev" v-show="appData"
                    >
                      Previous
                    </button>
                    <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 mr-10"
                        v-show="appData && isFormAccepted && !isWizardReadOnly"
                        @click.prevent="saveAppData"
                    >
                      Save Progress
                    </button>
                    <button
                      class="btn btn-outline-danger font-weight-bold text-uppercase px-9 py-4 mr-10"
                      v-show="appData && isFormAccepted && !isWizardReadOnly"
                      @click.prevent="confirmDeclineTransaction"
                    >
                      Decline
                    </button>

                    <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                            v-show="appData && isFormAccepted && !isLastPage"
                            @click.prevent="goToNextPage"
                    >
                      Next Step
                    </button>
                    <button
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-submit"
                      v-show="appData && !isWizardReadOnly"
                      @click.prevent="completeTransaction"
                      :disabled="!isCompleteTransactionButtonEnabled()"
                    >
                      Complete Transaction
                    </button>

                    <b-modal
                      ref="declineTransactionModal" title="Decline Transaction" size="md"
                      no-close-on-backdrop hide-header-close
                      @ok="declineTransaction" @cancel="resetFormModals"
                    >
                      <div>
                        <b-card-text>Enter the reason for declining the transaction:</b-card-text>
                        <div class="form-group row mb-0">
                          <b-form-textarea size="2000" class="form-control" v-model="declinationReason"/>
                        </div>
                        <b-card-text v-if="formModalError" class="text-danger mt-3">{{ formModalError }}</b-card-text>
                      </div>
                    </b-modal>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}
</style>

<script>
import _ from "lodash";
import FormData from "form-data";
import Swal from "sweetalert2";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import ApiService from "@/core/services/api.service";
import BrandService from "@/core/services/brand.service";
import ReviewPage from "@/view/pages/wizard/newbusiness-renewal/pages/1-ReviewPage.vue";
import InclusionsPage from "@/view/pages/wizard/newbusiness-renewal/pages/2-InclusionsPage.vue";
import FleetPage from "@/view/pages/wizard/newbusiness-renewal/pages/3-FleetPage.vue";
import PremiumPage from "@/view/pages/wizard/newbusiness-renewal/pages/4-PremiumPage.vue";
import FinalisationPage from "@/view/pages/wizard/newbusiness-renewal/pages/5-FinalisationPage.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import {ref} from "@vue/composition-api";

export default {
  name: "NewBusinessRenewal",
  components: {
    ReviewPage, InclusionsPage, PremiumPage, FleetPage, FinalisationPage
  },

  data() {
    return {
      wizardPages: [
        {
          id: 1,
          title: "Review",
          subtitle: "Review Client and Proposal Details"
        },
        {
          id: 2,
          title: "Inclusions & Conditions",
          subtitle: "Review Policy Inclusions and Conditions"
        },
        {
          id: 3,
          title: "Fleet",
          subtitle: "Review Fleet Details"
        },
        {
          id: 4,
          title: "Premium",
          subtitle: "Enter Premium Details"
        },
        {
          id: 5,
          title: "Finalisation",
          subtitle: "Finalise Transaction"
        }
      ],

      transactionId: null,
      formId: null,
      serverUrl: null,
      pageNumber: null,
      wizardObj: null,
      errorAlert: null,
      appData: null,
      appDataString: null,
      referenceData: null,

      loadingComparisonData: false,
      comparisonData: null,

      formModalError: null,

      outcome: null,
      outcomes: {
        acceptedByClient: "Accepted by Client",
        rejectedByClient: "Rejected by Client",
        declinedByInsurer: "Declined by Insurer",
      },

      ibaisMemoNumber: null,
      declinationReason: null,

      selectedInclusions: {
        policyConditions: [],
        excesses: [],
        generalBenefits: [],
      },
      deletedInclusions: [],
      allInclusions: {
        policyConditions: [],
        excesses: [],
        generalBenefits: [],
      },
      filteredInclusions: {
        policyConditions: [],
        excesses: [],
        generalBenefits: [],
      },
      selectedInclusionsChanged: false
    };
  },

  mounted() {
    // Show page loading
    this.startPageLoading();

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.userId) {
      this.handleError("Unauthorised access!");
      return;
    }

    this.formId = queryParams.formId || null;
    this.transactionId = queryParams.transactionId || null;
    this.userId = queryParams.userId || null;
    this.pageNumber = queryParams.pageNumber || null;
    if(!_.isNil(this.pageNumber)) {
      this.pageNumber = Number(this.pageNumber);
    }

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }

    const token = queryParams.token || null;
    if (token) {
      ApiService.saveTokenAndSetHeader(token);
    }

    // Get Application Data
    this.getAppData();
  },

  computed: {
    isWizardReadOnly: function () {
      if(this.appData) {
        let txnStatus = _.get(this.appData, 'transaction.K_Status');
        return txnStatus === "Completed";
      }
      return true;
    },

    isBrandFuse: function () {
      return this.appData?.transaction?.K_Brand === 'Fuse';
    },

    isFormAccepted: function () {
      if(this.appData) {
        let formStatus = _.get(this.appData, 'form.K_Status');
        return formStatus === "Accepted";
      }
      return false;
    },

    isFormVoided: function () {
      if(this.appData) {
        let formStatus = _.get(this.appData, 'form.K_Status');
        return formStatus === "Voided";
      }
      return false;
    },

    isFormForRenewal: function() {
      if(this.appData) {
        let formType = _.get(this.appData, 'form.K_FormType');
        return formType === "Renewal Declaration Form";
      }
      return false;
    },

    isRenewalTransaction: function () {
      if(this.appData) {
        let txnType = _.get(this.appData, 'transaction.K_Type');
        return txnType === "Renewal";
      }
      return false;
    },

    isLastPage: function () {
      return this.wizardObj && this.wizardObj.isLastStep();
    },

    premiumPageExpandAllSections: function () {
      return _.isNumber(this.pageNumber) && this.pageNumber === 4;
    },

    uwApprovalsCompleted: function() {
      if(this.appData) {
        const approvals = this.appData.uwApprovals;
        if(Array.isArray(approvals) && approvals.length > 0) {
          return !_.some(approvals, approval => {
            return approval.K_ApprovalStatus !== 'Approved';
          })
        }
        else {
          return true;
        }
      }
      return false;
    }
  },

  methods: {
    ref,

    refreshAppData: function () {
      return ApiService.post(this.serverUrl + "/nb/get-wizard-app-data", {
        formId: this.formId,
        transactionId: this.transactionId,
        userId: this.userId,
      })
    },

    getAppData: function () {
      // Show page loading
      this.startPageLoading();

      this.refreshAppData()
        .then(({ data }) => {
          this.appData = data.result;
          this.appDataString = stringify(this.appData);

          // Set UI Brand
          BrandService.setUIBrand(this.appData.form?.K_Brand);

          this.calculateAllTotals();

          this.$nextTick(()=> {
            // Set wizard title and subtitle
            this.setTitles();

            // Set local variables
            if(this.isFormForRenewal) {
              this.ibaisMemoNumber = this.appData.form.K_IbaisMemoNumber;
            }

            if (this.appData && this.appData.transaction) {
              // Set transactionId
              if (!this.transactionId) {
                this.transactionId = this.appData.transaction.id;
              }

              // For Renewal transaction, pre-fill the iBais Memo Number
              if (this.isRenewalTransaction) {
                this.ibaisMemoNumber = this.appData.transaction.K_IbaisMemoNumber;
              }

              // Set outcome and reason - if available
              this.outcome = this.appData.transaction.K_Outcome || null;
              if (this.outcome === this.outcomes.acceptedByClient) {
                this.ibaisMemoNumber = this.appData.transaction.K_IbaisMemoNumber;
              }
              else if (this.outcome === this.outcomes.declinedByInsurer) {
                this.declinationReason = this.appData.transaction.K_Reason;
              }
            }

            // Set starting step for the wizard
            let startStep = 1;
            if (this.isWizardReadOnly) {
              // If transaction is already completed, directly start the wizard on last page.
              startStep = this.wizardPages.length;
            }
            else if (this.appData && this.pageNumber) {
              // Start from the given page number
              startStep = Number(this.pageNumber);
            }
            else if (this.isFormAccepted && this.appData && this.appData.currentPage) {
              // Check if the user was previously on a particular wizard page
              startStep = this.appData.currentPage;
            }

            // Initialise the UI wizard
            this.initWizard(startStep);

            // Get inclusions in the background
            this.getAllInclusions();

            // Get reference data in the background
            this.getReferenceData();

            // Get form-CRM-comparison data if needed
            this.getFormCRMComparisonData();

            // Stop page loading
            this.stopPageLoading();
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setTitles: function () {
      // Set wizard title and sub-title in global store so that it can be used by the header
      if (this.appData) {

        if(this.appData.user) {
          this.$store.commit("setUser", this.appData.user);
        }

        if (this.appData.policy && this.appData.transaction) {
          let transactionType = _.toUpper(this.appData.transaction.K_Type);
          let policyNumber = this.appData.policy.K_PolicyNumber;

          let title = `${transactionType} - ${policyNumber}`;
          this.$store.commit("setWizardTitle", title);

          if (this.appData.client) {
            this.$store.commit("setWizardSubtitle", this.appData.client.Name);
          }
        }
        else if (this.appData.form) {
          let formType = this.appData.form.K_FormType || 'Motor Proposal Form';
          this.$store.commit("setWizardTitle", formType);

          this.$store.commit("setWizardSubtitle", this.appData.form.K_ClientNamePerForm);
        }
      }
    },

    checkClientAndAcceptForm: function () {
      // Renewal Form
      if(this.isFormForRenewal) {
        this.acceptForm();
      }
      // New Motor Proposal Form
      else {
        const ibaisClientNumber = _.toUpper(this.appData.form.K_IbaisClientNumber);
        ApiService.get(`${this.serverUrl}/mpf/client-search`,
            { params: { brand: this.appData.form.K_Brand, ibaisClientNumber: ibaisClientNumber }})
            .then(({ data }) => {
              const existingClient = data.result;

              if(existingClient && existingClient.id) {
                const message = `Existing client/prospect found in the CRM for ${ibaisClientNumber}. Form data will be copied over the existing record.<br/><br/>Are you sure you want to continue?`;
                Swal.fire({
                  titleText: `Existing Client or Prospect`,
                  html: `<p>${message}</p>`,
                  icon: "warning",
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  confirmButtonText: 'Yes',
                  showConfirmButton: true,
                  showCancelButton: true,
                  showCloseButton: false
                })
                  .then(result => {
                    if(result && result.isConfirmed) {
                      this.acceptForm();
                    }
                  });
              }
              else {
                this.acceptForm();
              }
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
    },

    acceptForm: function () {
      // Show page loading
      this.startPageLoading();

      let url = null;
      let formVersion = this.appData.form.K_FormVersion;
      // Old proposal form
      if(!formVersion || formVersion === '1') {
        url = `${this.serverUrl}/nb/accept-motor-proposal-form`;
      }
      else {
        url = `${this.serverUrl}/mpf/form/${this.formId}/accept`;
      }

      ApiService.post(url, {
        formId: this.formId,
        ibaisClientNumber: _.toUpper(this.appData.form.K_IbaisClientNumber),
        ibaisMemoNumber: _.toUpper(this.ibaisMemoNumber),
        userId: this.userId,
      })
        .then(({ data }) => {
          this.transactionId = data.result;

          this.getAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    voidForm: function (voidReason, createCrmRecordsForVoidedForm) {
      this.startPageLoading();

      let url = null;
      let formVersion = this.appData.form.K_FormVersion;
      // Old proposal form
      if(!formVersion || formVersion === '1') {
        url = `${this.serverUrl}/nb/void-motor-proposal-form`;
      }
      else {
        url = `${this.serverUrl}/mpf/form/${this.formId}/void`;
      }

      ApiService.post(url, {
        formId: this.formId,
        voidReason: voidReason,
        createCrmRecords: createCrmRecordsForVoidedForm,
        ibaisClientNumber: createCrmRecordsForVoidedForm ? this.appData.form.K_IbaisClientNumber : null,
        userId: this.userId,
      })
        .then(() => {
          this.appData = null;
          this.appDataString = null;

          this.setError("This form is now voided. Please close this window.");
          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    unvoidForm: function (unvoidReason) {
      this.startPageLoading();

      let url = null;
      let formVersion = this.appData.form.K_FormVersion;
      // Old proposal form
      if(!formVersion || formVersion === '1') {
        url = `${this.serverUrl}/nb/unvoid-motor-proposal-form`;
      }
      else {
        url = `${this.serverUrl}/mpf/form/${this.formId}/unvoid`;
      }

      ApiService.post(url, {
        formId: this.formId,
        unvoidReason: unvoidReason,
        userId: this.userId,
      })
        .then(() => {
          this.getAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    confirmDeclineTransaction: function () {
      this.$refs["declineTransactionModal"].show();
    },

    declineTransaction: function (bvModalEvent) {
      this.formModalError = null;
      if (!this.declinationReason) {
        this.formModalError = "Please enter the required information";
        bvModalEvent.preventDefault();
        return;
      }

      // Set outcome
      this.outcome = this.outcomes.declinedByInsurer;

      // Decline and complete the transaction
      this.completeTransaction();
    },

    resetFormModals: function () {
      this.formModalError = null;
    },

    getAllInclusions: function () {
      if (!this.transactionId) {
        return;
      }

      // Start page loading
      this.startPageLoading();

      Promise.all([
        // Get inclusions for the transaction
        this.getInclusions(this.transactionId),

        // Get all inclusions for the brand
        this.getInclusions(),
      ]).then((values) => {
        // Save already selected transaction inclusions
        const transactionInclusions = values[0].data.result;

        this.selectedInclusions.generalBenefits = this.setInclusionBusinessActivity(transactionInclusions.generalBenefits);
        this.selectedInclusions.excesses = this.setInclusionBusinessActivity(transactionInclusions.excesses);
        this.selectedInclusions.policyConditions = this.setInclusionBusinessActivity(transactionInclusions.policyConditions);
        this.deletedInclusions = this.setInclusionBusinessActivity(transactionInclusions.deletedInclusions);

        // Save all available brand inclusions
        const brandInclusions = values[1].data.result;
        this.allInclusions.generalBenefits = this.setInclusionBusinessActivity(brandInclusions.generalBenefits);
        this.allInclusions.excesses = this.setInclusionBusinessActivity(brandInclusions.excesses);
        this.allInclusions.policyConditions = this.setInclusionBusinessActivity(brandInclusions.policyConditions);

        this.processInclusions();

        // Stop page loading
        this.stopPageLoading();
      });
    },

    getInclusions: function (transactionId) {
      let brand = this.appData.form.K_Brand || null;
      return ApiService.post(this.serverUrl + "/nb/inclusions", {
        transactionId: transactionId || null,
        brand: brand,
      });
    },

    setInclusionBusinessActivity: function (inclusions) {
      const allBAs = this.appData.allBusinessActivities;

      if (!Array.isArray(inclusions)) {
        inclusions = [];
      }

      inclusions.forEach(function (inclusion) {
        const incBA = inclusion.K_BusinessActivity;

        if(incBA) {
          allBAs.forEach((ba) => {
            inclusion[ba.K_BusinessActivityKey] = (incBA.id === ba.id);
          });
        }
      });
      return inclusions;
    },

    processInclusions: function () {
      // Set filtered inclusions based on chosen business activities.
      this.filterInclusionsBasedOnBusinessActivities();

      // Remove invalid inclusions from selected inclusions (based on the newly filtered inclusions)
      this.removeInvalidInclusions(this.selectedInclusions.generalBenefits, this.filteredInclusions.generalBenefits);
      this.removeInvalidInclusions(this.selectedInclusions.excesses, this.filteredInclusions.excesses);
      this.removeInvalidInclusions(this.selectedInclusions.policyConditions, this.filteredInclusions.policyConditions);

      // Programmatically select all applicable (and non-deleted) inclusions
      const missingGeneralBenefits = this.getNotSelectedInclusions(this.selectedInclusions.generalBenefits, this.filteredInclusions.generalBenefits);
      this.selectedInclusions.generalBenefits = this.selectedInclusions.generalBenefits.concat(missingGeneralBenefits);

      const missingExcesses = this.getNotSelectedInclusions(this.selectedInclusions.excesses, this.filteredInclusions.excesses);
      this.selectedInclusions.excesses = this.selectedInclusions.excesses.concat(missingExcesses);

      const missingPolicyConditions = this.getNotSelectedInclusions(this.selectedInclusions.policyConditions, this.filteredInclusions.policyConditions);
      this.selectedInclusions.policyConditions = this.selectedInclusions.policyConditions.concat(missingPolicyConditions);

      this.selectedInclusionsChanged = true;
    },

    filterInclusionsBasedOnBusinessActivities: function () {
      let generalBenefits = [];
      let excesses = [];
      let policyConditions = [];

      if(this.isBrandFuse || !this.appData.transaction.selectedBusinessActivities) {
        generalBenefits = this.allInclusions.generalBenefits || [];
        excesses = this.allInclusions.excesses || [];
        policyConditions = this.allInclusions.policyConditions || [];
      }
      else {
        // For all chosen business activities (i.e. value=true), get their inclusions and add to filtered inclusions.
        for (const [key, value] of Object.entries(this.appData.transaction.selectedBusinessActivities)) {
          if (value === true) {
            generalBenefits = generalBenefits.concat(
                _.filter(this.allInclusions.generalBenefits, function (inclusion) {
                  return inclusion[key] === true;
                })
            );

            excesses = excesses.concat(
                _.filter(this.allInclusions.excesses, function (inclusion) {
                  return inclusion[key] === true;
                })
            );

            policyConditions = policyConditions.concat(
                _.filter(this.allInclusions.policyConditions, function (inclusion) {
                  return inclusion[key] === true;
                })
            );
          }
        }
      }

      this.filteredInclusions.generalBenefits = generalBenefits;
      this.filteredInclusions.excesses = excesses;
      this.filteredInclusions.policyConditions = policyConditions;
    },

    removeInvalidInclusions: function (selectedInclusions, filteredInclusions) {
      _.remove(selectedInclusions, (inclusion) => {
        return !_.some(filteredInclusions, { Name: inclusion.Name });
      });
    },

    getNotSelectedInclusions: function (selectedInclusions, filteredInclusions) {
      let missingInclusions = _.filter(filteredInclusions, (inclusion) => {
        return (
          !_.some(selectedInclusions, { Name: inclusion.Name }) &&
          !_.some(this.deletedInclusions, { Name: inclusion.Name })
        );
      });
      return Array.isArray(missingInclusions) ? missingInclusions : [];
    },

    getDeletedInclusions: function (selectedInclusions, filteredInclusions) {
      let deletedInclusions = _.filter(filteredInclusions, (inclusion) => {
        return !_.some(selectedInclusions, { Name: inclusion.Name });
      });
      return Array.isArray(deletedInclusions) ? deletedInclusions : [];
    },

    updateInclusions: function (inclusionKey, inclusions) {
      this.selectedInclusions[inclusionKey] = inclusions;
      this.selectedInclusionsChanged = true;

      // Re-calculate deleted inclusions
      let deletedInclusions = [];
      deletedInclusions = deletedInclusions.concat(this.getDeletedInclusions(this.selectedInclusions.generalBenefits, this.filteredInclusions.generalBenefits));
      deletedInclusions = deletedInclusions.concat(this.getDeletedInclusions(this.selectedInclusions.excesses, this.filteredInclusions.excesses));
      deletedInclusions = deletedInclusions.concat(this.getDeletedInclusions(this.selectedInclusions.policyConditions, this.filteredInclusions.policyConditions));
      this.deletedInclusions = deletedInclusions;
    },

    setInclusionsChanged: function () {
      this.selectedInclusionsChanged = true;
    },

    getReferenceData: function () {
      if (!this.transactionId) {
        return;
      }

      ApiService.post(this.serverUrl + "/nb/ref-data", {userId: this.userId})
          .then(({ data }) => {
            this.referenceData = data.result;
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    getFormCRMComparisonData: function () {
      if (!this.formId || this.isFormAccepted || this.isFormVoided) {
        return;
      }

      // Get the comparison data only for Form v2
      let formVersion = _.get(this.appData, 'form.K_FormVersion');
      if(formVersion === '2' || formVersion === '3') {
        this.loadingComparisonData = true;
        ApiService.get(`${this.serverUrl}/mpf/form/${this.formId}/compare`, { params: { userId: this.userId }})
          .then(({ data }) => {
            this.comparisonData = data.result;
            this.loadingComparisonData = false;
          })
          .catch((error) => {
            this.loadingComparisonData = false;
            this.handleError(error);
          });
      }
    },

    createQuoteDocument: function (uiDoc) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.appData.documents.quoteDocument = null;
      this.appData.quoteDocumentSent = false;

      this.outcome = null;
      this.appData.transaction.K_Reason = null;
      this.declinationReason = null;

      // Show doc processing
      this.$set(uiDoc, 'processing', true);

      ApiService.post(this.serverUrl + "/nb/create-document", {
        documentType: uiDoc.docgenDocType,
        transactionId: this.transactionId,
        userId: this.userId,
      })
        .then(({ data }) => {
          this.appData.documents[uiDoc.docType] = data.result;

          this.saveAppData(true);

          // Stop showing doc as processing
          this.$set(uiDoc, 'processing', false);

          // Stop page loading
          this.stopPageLoading();
        })
        .catch((error) => {
          // Stop showing doc as processing
          uiDoc.processing = false;

          this.handleError(error);
        });
    },

    setOutcome: function (outcome) {
      this.outcome = outcome;
    },

    setMemoNumber: function (memoNumber) {
      this.ibaisMemoNumber = memoNumber;
    },

    initWizard: function (startStep) {
      // Initialize form wizard
      const wizard = new KTWizard("kt_wizard_v4", {
        startStep: 1, // initial active step number
        clickableSteps: true, // allow step clicking
      });

      this.wizardObj = wizard;

      if (startStep !== 1) {
        setTimeout(function () {
          wizard.goTo(startStep, true);
        }, 0);
      }

      // // Before-Next event - Validation before going to next page
      // wizard.on("beforeNext", (wizardObj, nextPage) => {
      // });

      // Before-Prev event - Validation before going to previous page
      wizard.on("beforePrev", (wizardObj, prevPage) => {
        this.clearError();

        // Save current page number in app-data.
        if (this.appData) {
          this.appData.currentPage = prevPage;

          // Save app-data to server
          this.saveAppData();
        }
      });

      // // Before-Change event - Save app-data and inclusions to server
      // wizard.on("beforeChange", (wizardObj, newPage) => {
      // });

      // Change event - Scroll to top of the page
      wizard.on("change", (/*wizardObj*/) => {
        setTimeout(function () {
          KTUtil.scrollTop();
        }, 500);
      });
    },

    saveAppData: function (noSpinner) {
      if (this.isWizardReadOnly) {
        return;
      }

      // Save app-data to the server (only if it has changed)
      let currentAppDataString = stringify(this.appData);
      if (currentAppDataString !== this.appDataString) {
        if (!noSpinner) {
          // Show page loading
          this.startPageLoading();
        }

        this.saveToServer()
          .then(() => {
            this.appDataString = currentAppDataString;

            // Save inclusions to the server if selection has changed
            if (this.selectedInclusionsChanged) {
              let inclusionsData = {
                generalBenefits: this.selectedInclusions.generalBenefits,
                excesses: this.selectedInclusions.excesses,
                policyConditions: this.selectedInclusions.policyConditions,
                deletedInclusions: this.deletedInclusions,
              };

              ApiService.post(this.serverUrl + "/nb/save-inclusions", {
                transactionId: this.transactionId,
                inclusionsData: inclusionsData,
                userId: this.userId,
              }).then(() => {
                // Reset the flag
                this.selectedInclusionsChanged = false;
                // Stop page loading
                this.stopPageLoading();
              });
            } else {
              // Stop page loading
              this.stopPageLoading();
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },

    saveToServer: function () {
      let currentAppDataString = stringify(this.appData);
      if (currentAppDataString !== this.appDataString) {
        // Wizard-app-data has changed - save to server
        return ApiService.post(this.serverUrl + "/nb/save-wizard-app-data", {
          transactionId: this.transactionId,
          appData: this.appData,
          userId: this.userId,
        });
      }
      // Just return an empty resolved promise as no saving is needed.
      return Promise.resolve();
    },

    isCompleteTransactionButtonEnabled: function () {
      if (this.isWizardReadOnly || !this.outcome) {
        return false;
      }

      if (this.outcome === this.outcomes.acceptedByClient && this.ibaisMemoNumber) {
        return true;
      }
      else if (this.outcome === this.outcomes.rejectedByClient && this.appData.transaction.K_Reason) {
        return true;
      }
      else if (this.outcome === this.outcomes.declinedByInsurer && this.declinationReason) {
        return true;
      }
      return false;
    },

    completeTransaction: function () {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      if (this.outcome === this.outcomes.acceptedByClient) {
        if(!this.appData.uwApprovalsDataValid) {
          this.setError('Invalid UW-Approvals - Please click the \'Refresh Approvals\' button to refresh the data.');
          return;
        }

        if(!this.uwApprovalsCompleted) {
          this.setError('All Approvals must be completed before proceeding further.');
          return;
        }
      }

      // Show page loading
      this.startPageLoading();

      let outcomeData = null;
      if (this.outcome === this.outcomes.acceptedByClient) {
        outcomeData = this.ibaisMemoNumber;
      }
      else if (this.outcome === this.outcomes.rejectedByClient) {
        outcomeData = this.appData.transaction.K_Reason;
      }
      else if (this.outcome === this.outcomes.declinedByInsurer) {
        outcomeData = this.declinationReason;
      }

      ApiService.post(this.serverUrl + "/nb/complete-transaction", {
        transactionId: this.transactionId,
        transactionType: this.appData.transaction.K_Type,
        outcome: this.outcome,
        outcomeData: outcomeData,
        userId: this.userId,
      })
        .then(() => {
          // Set transaction as completed
          this.appData.transaction.K_Status = "Completed";

          // Go to last page
          let currentStep = this.wizardObj.getStep();
          let lastStep = this.wizardPages.length;
          if (currentStep !== lastStep) {
            this.wizardObj.goTo(lastStep, false);
          }

          setTimeout(() => {
            // Stop page loading
            this.stopPageLoading();

            KTUtil.scrollTop();
          }, 0);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    goToNextPage: async function () {
      this.clearError();

      let isNavAllowed = true;
      let currentStep = this.wizardObj.getStep();
      if(!this.isWizardReadOnly) {
        isNavAllowed = await this.isPageValid(currentStep);
      }

      if(isNavAllowed) {
        // Save current page number in app-data.
        this.appData.currentPage = currentStep + 1;

        // Save app-data to server
        this.saveAppData();

        this.wizardObj.goNext();
      }

      // let errors = [];
      // let isNavAllowed = this.isNavigationToPageAllowed(nextPage, errors);
      // if (!isNavAllowed) {
      //   if (errors.length > 0) {
      //     let errorMsgs = errors.join("<br />");
      //     this.setError(errorMsgs);
      //   }
      // } else {
      //   this.wizardObj.goTo(nextPage, true);
      // }
    },

    isPageValid: async function(pageNumber) {
      let isValid = false;
      if(!this.$refs || !this.$refs.nbRnWizard || !this.appData){
        return isValid;
      }

      let page = _.find(this.wizardPages, { 'id': pageNumber });
      if(!page) {
        console.log(`Invalid page number ${pageNumber}`);
        return isValid;
      }

      let wizardPageId = `wizardPage${page.id}`;
      let isDevelopment = process.env.NODE_ENV === 'development';

      let formChildren = this.$refs.nbRnWizard.$children;
      if(Array.isArray(formChildren)) {
        let currentPageComponent = _.find(formChildren, child => {
          return child.$el && child.$el.id === wizardPageId;
        });
        if(!currentPageComponent) {
          if(isDevelopment) {
            console.log(`Could not find currentPageComponent for id ${wizardPageId}`);
          }
        }
        else {
          let currentPageRef = currentPageComponent.$refs[wizardPageId];
          if(!currentPageRef) {
            if(isDevelopment) {
              console.log(`Could not find currentPageRef for id ${wizardPageId}`);
            }
          }
          else {
            isValid = await currentPageRef.validate();
            if(!isValid && isDevelopment) {
              console.log(currentPageRef.errors);
            }
          }
        }
      }
      return isValid;
    },

    getVehicleSummaryFromFleetDeclaration: function () {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      // Show page loading
      this.startPageLoading();

      this.saveToServer()
        .then(() => {
          ApiService.post(`${this.serverUrl}/nb/fleet-vehicle-summary`, {
            transactionId: this.transactionId,
            userId: this.userId,
          })
            .then(() => {
              this.refreshAppData()
                  .then(({ data }) => {
                    this.appData = data.result;
                    this.appDataString = stringify(this.appData);

                    this.calculateAllTotals();

                    this.stopPageLoading();
                  })
                  .catch((error) => {
                    this.handleError(error);
                  });
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    refreshFleetStatus: function () {
      this.startPageLoading();

      ApiService.post(`${this.serverUrl}/nb/transaction-fleet/refresh`, {
        transactionId: this.transactionId,
        userId: this.userId,
      })
          .then(({ data }) => {
            const fleetInfo = data.result;
            this.$set(this.appData, 'fleetInfo', fleetInfo);

            this.stopPageLoading();
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    downloadTransactionFleet: function() {
      this.$set(this.appData.fleetInfo, 'downloadingTransactionFleet', true);

      ApiService.get(`${this.serverUrl}/nb/${this.transactionId}/transaction-fleet`)
        .then(({ data }) => {
          const fleetCsv = data.result;
          if(fleetCsv) {
            const url = window.URL.createObjectURL(new Blob([fleetCsv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Transaction_Fleet.csv`);
            document.body.appendChild(link);
            link.click();
          }
          else {
            this.setError(`Fleet data could not be downloaded. Please try again.`);
          }
          this.$set(this.appData.fleetInfo, 'downloadingTransactionFleet', false);
        })
        .catch((error) => {
          this.$set(this.appData.fleetInfo, 'downloadingTransactionFleet', false);
          this.handleError(error);
        });
    },

    saveTransactionFleet: function(newFleet) {
      this.startPageLoading();

      ApiService.post(`${this.serverUrl}/nb/transaction-fleet`, {
        transactionId: this.transactionId,
        fleet: newFleet,
        userId: this.userId,
      })
        .then(({ data }) => {
          const fleetInfo = data.result;
          this.$set(this.appData, 'fleetInfo', fleetInfo);

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    uwFleetUploadCompleted: function (result, data) {
      if (result && data) {
        this.startPageLoading();

        ApiService.post(`${this.serverUrl}/nb/transaction-uw-fleet`, {
          transactionId: this.transactionId,
          uwFleetImportId: data.import_id,
          userId: this.userId,
        })
            .then(( { data }) => {
              const fleetInfo = data.result;
              this.$set(this.appData, 'fleetInfo', fleetInfo);

              this.stopPageLoading();
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
      else {
        this.setError("Fleet Upload failed! Please try again.");
      }
    },

    calculatePremiums: function() {
        this.clearError();
        if (this.isWizardReadOnly) {
            return;
        }

        // Show page loading
        this.startPageLoading();

        this.saveToServer()
          .then(() => {
            ApiService.post(this.serverUrl + "/nb/rating", {
                transactionId: this.transactionId,
                userId: this.userId,
            })
              .then(() => {
                this.refreshAppData()
                    .then(({ data }) => {
                      this.appData = data.result;
                      this.appDataString = stringify(this.appData);

                      this.calculateAllTotals();

                      this.stopPageLoading();
                    })
                    .catch((error) => {
                      this.handleError(error);
                    });
              })
              .catch((error) => {
                this.handleError(error);
              });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    savePremiumPage: function() {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      this.startPageLoading();

      this.saveToServer()
          .then(() => {
            this.appDataString = stringify(this.appData);

            ApiService.post(this.serverUrl + "/nb/premium-page-pdf", {
              transactionId: this.transactionId,
              userId: this.userId,
            })
              .then(({ data }) => {
                this.stopPageLoading();
              })
              .catch((error) => {
                this.handleError(error);
              });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    refreshClaimsHistory: function() {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      this.startPageLoading();

      this.saveToServer()
          .then(() => {
            ApiService.post(this.serverUrl + "/nb/refresh-claims-history", {
              transactionId: this.transactionId,
              userId: this.userId,
            })
              .then(() => {
                this.refreshAppData()
                  .then(({ data }) => {
                    this.appData = data.result;
                    this.appDataString = stringify(this.appData);

                    this.calculateAllTotals();

                    this.stopPageLoading();
                  })
                  .catch((error) => {
                    this.handleError(error);
                  });
              })
              .catch((error) => {
                this.handleError(error);
              });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    refreshUwApprovals: function() {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      // Show page loading
      this.startPageLoading();

      this.saveToServer()
        .then(() => {
          ApiService.post(this.serverUrl + "/nb/uw-approvals", {
            transactionId: this.transactionId,
            userId: this.userId,
          })
            .then(({ data }) => {
              this.$set(this.appData, 'uwApprovals', data.result || []);
              this.$set(this.appData, 'uwApprovalsDataValid', true);

              this.appDataString = stringify(this.appData);

              this.stopPageLoading();
            })
            .catch((error) => {
              this.appData.uwApprovalsDataValid = true;
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    approveApproval: function (approval) {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      this.startPageLoading();
      this.uploadApprovalAttachments(approval)
          .then(() => {
            ApiService.post(this.serverUrl + "/nb/uw-approval/approve", {
              transactionId: this.transactionId,
              userId: this.userId,
              approvalId: approval.id,
              comments: approval.K_Comments
            })
                .then(({ data }) => {
                  this.$set(this.appData, 'uwApprovals', data.result || []);
                  this.$set(this.appData, 'uwApprovalsDataValid', true);

                  this.appDataString = stringify(this.appData);

                  this.stopPageLoading();
                })
                .catch((error) => {
                  this.handleError(error);
                });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    rejectApproval: function (approval) {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      this.startPageLoading();
      this.uploadApprovalAttachments(approval)
          .then(() => {
            ApiService.post(this.serverUrl + "/nb/uw-approval/reject", {
              transactionId: this.transactionId,
              userId: this.userId,
              approvalId: approval.id,
              comments: approval.K_Comments
            })
              .then(({ data }) => {
                this.$set(this.appData, 'uwApprovals', data.result || []);
                this.$set(this.appData, 'uwApprovalsDataValid', true);

                this.appDataString = stringify(this.appData);

                this.stopPageLoading();
              })
              .catch((error) => {
                this.handleError(error);
              });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    uploadApprovalAttachments: function (approval) {
      if(Array.isArray(approval.selectedFiles) && approval.selectedFiles.length > 0) {
        const formData = new FormData();
        for(const [i, selectedFile] of approval.selectedFiles.entries()) {
          formData.append(`attachments[${i}]`, selectedFile);
        }
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        };

        return ApiService.post(this.serverUrl + `/nb/uw-approval/${approval.id}/attachments`, formData, config);
      }
      else {
        // Just return an empty resolved promise as no saving is needed.
        return Promise.resolve();
      }
    },

    calculateAllTotals: function() {
      this.calculateVehiclePremiumTotals();
      this.calculateStateSummaryTotals();
      this.calculateBusinessActivityPercentTotal();
      this.calculatePostcodeDistributionTotal();
      this.calculateNetIncurredTotals();
      this.calculateTotalFees();
    },

    calculateVehiclePremiumTotals: function() {
      if (this.appData && this.appData.transaction && Array.isArray(this.appData.transaction.K_VehiclesSummary)) {
        let totalNumberOfVehicles = 0;
        let totalMarketValue = 0;
        let totalBasePremium = 0;
        let totalBasePremiumUwOverride = 0;
        let totalRatePerVehicle = 0;

        let vehiclesSummary = this.appData.transaction.K_VehiclesSummary;
        vehiclesSummary.forEach(vehicleCategory => {
          const numberOfVehicles = vehicleCategory.K_NumberOfVehicles ? Number(vehicleCategory.K_NumberOfVehicles) : 0;

          /** Total number of branches */
          totalNumberOfVehicles = totalNumberOfVehicles + numberOfVehicles;

          /** Total of Total-Market-Value */
          if (vehicleCategory.K_TotalMarketValue) {
            totalMarketValue = totalMarketValue + Number(vehicleCategory.K_TotalMarketValue);
          }

          /** Total Base Premium per Vehicle */
          if (vehicleCategory.K_BasePremiumPerVehicle) {
            totalBasePremium = totalBasePremium + (numberOfVehicles * Number(vehicleCategory.K_BasePremiumPerVehicle));
          }

          /** Total Base Premium per Vehicle UW Override*/
          if (vehicleCategory.K_BasePremiumPerVehicleUwOverride) {
            totalBasePremiumUwOverride = totalBasePremiumUwOverride + (numberOfVehicles * Number(vehicleCategory.K_BasePremiumPerVehicleUwOverride));
          }

          /** Total Rate per Vehicle} */
          if (vehicleCategory.K_RatePerVehicle) {
            totalRatePerVehicle = totalRatePerVehicle + (numberOfVehicles * Number(vehicleCategory.K_RatePerVehicle));
          }
        });

        this.$set(this.appData.transaction, 'K_TotalNumberOfVehicles', totalNumberOfVehicles || null);
        this.$set(this.appData.transaction, 'K_AverageTotalMarketValue', totalNumberOfVehicles ? (totalMarketValue / totalNumberOfVehicles).toFixed(2) : null);
        this.$set(this.appData.transaction, 'K_AverageBasePremiumPerVehicle', totalNumberOfVehicles ? (totalBasePremium / totalNumberOfVehicles).toFixed(2) : null);
        this.$set(this.appData.transaction, 'K_AverageBasePremiumPerVehicleUwOverride', totalNumberOfVehicles ? (totalBasePremiumUwOverride / totalNumberOfVehicles).toFixed(2) : null);
        this.$set(this.appData.transaction, 'K_AverageRatePerVehicle', totalNumberOfVehicles ? (totalRatePerVehicle / totalNumberOfVehicles).toFixed(2) : null);
      }
    },

    calculateStateSummaryTotals: function() {
      let totalEslFsl = 0;
      let totalStampDuty = 0;
      let totalGst = 0;
      let totalAnnualPremium = 0;

      if (this.appData && this.appData.transaction && Array.isArray(this.appData.transaction.K_StateSummary)) {
        this.appData.transaction.K_StateSummary.forEach(state => {
          if (state.K_EslFsl) {
            totalEslFsl = totalEslFsl + Number(state.K_EslFsl);
          }
          if (state.K_StampDuty) {
            totalStampDuty = totalStampDuty + Number(state.K_StampDuty);
          }
          if (state.K_Gst) {
            totalGst = totalGst + Number(state.K_Gst);
          }
          if (state.K_AnnualPremium) {
            totalAnnualPremium =
                totalAnnualPremium + Number(state.K_AnnualPremium);
          }
        });
        this.$set(this.appData.transaction, 'stateSummaryTotals', {
          totalEslFsl: totalEslFsl.toFixed(2),
          totalStampDuty: totalStampDuty.toFixed(2),
          totalGst: totalGst.toFixed(2),
          totalAnnualPremium: totalAnnualPremium.toFixed(2)
        });
      }
    },

    calculateBusinessActivityPercentTotal: function() {
      let businessActivitiesPercentTotal = 0;
      if (this.appData && this.appData.transaction) {
        let businessActivities = this.appData.transaction.K_BusinessActivitiesJson;
        if (Array.isArray(businessActivities)) {
          businessActivities.forEach(ba => {
            if (ba.percentage) {
              businessActivitiesPercentTotal = businessActivitiesPercentTotal + Number(ba.percentage);
            }
          });
        }
        this.$set(this.appData.transaction, 'businessActivitiesPercentTotal', this.round(businessActivitiesPercentTotal));
      }
    },

    calculatePostcodeDistributionTotal: function () {
      let pdTotal = 0;
      if (this.appData && this.appData.transaction) {
        let pdJson = this.appData.transaction.K_PostcodeDistributionJson;
        if (Array.isArray(pdJson)) {
          pdJson.forEach(pd => {
            if (pd.percentage) {
              pdTotal = pdTotal + Number(pd.percentage);
            }
          });
        }
        this.$set(this.appData.transaction, 'pdTotal', this.round(pdTotal));
      }
    },

    calculateNetIncurredTotals: function () {
      if (this.appData && this.appData.transaction) {
        const txn = this.appData.transaction;

        const niCurrentYear =
            this.toNumber(txn.K_NetIncurredWorkingLossesCurrentYear)
          + this.toNumber(txn.K_NetIncurredLargeLossesCurrentYear)
          + this.toNumber(txn.K_NetIncurredNaturalPerilLossesCurrentYear);
        const ni1YearAgo =
            this.toNumber(txn.K_NetIncurredWorkingLosses1YearAgo)
          + this.toNumber(txn.K_NetIncurredLargeLosses1YearAgo)
          + this.toNumber(txn.K_NetIncurredNaturalPerilLosses1YearAgo);
        const ni2YearsAgo =
            this.toNumber(txn.K_NetIncurredWorkingLosses2YearsAgo)
          + this.toNumber(txn.K_NetIncurredLargeLosses2YearsAgo)
          + this.toNumber(txn.K_NetIncurredNaturalPerilLosses2YearsAgo);
        const ni3YearsAgo =
            this.toNumber(txn.K_NetIncurredWorkingLosses3YearsAgo)
          + this.toNumber(txn.K_NetIncurredLargeLosses3YearsAgo)
          + this.toNumber(txn.K_NetIncurredNaturalPerilLosses3YearsAgo);
        const ni4YearsAgo =
            this.toNumber(txn.K_NetIncurredWorkingLosses4YearsAgo)
          + this.toNumber(txn.K_NetIncurredLargeLosses4YearsAgo)
          + this.toNumber(txn.K_NetIncurredNaturalPerilLosses4YearsAgo);

        this.$set(this.appData.transaction, 'niCurrentYear', this.round(niCurrentYear));
        this.$set(this.appData.transaction, 'ni1YearAgo', this.round(ni1YearAgo));
        this.$set(this.appData.transaction, 'ni2YearsAgo', this.round(ni2YearsAgo));
        this.$set(this.appData.transaction, 'ni3YearsAgo', this.round(ni3YearsAgo));
        this.$set(this.appData.transaction, 'ni4YearsAgo', this.round(ni4YearsAgo));
      }
    },

    toNumber: function(number) {
      if(_.isNumber(number)) {
        return number;
      }
      return 0;
    },

    calculateTotalFees: function (uwPremiumOverride) {
      if(this.appData && this.appData.transaction) {
        if(this.isBrandFuse) {
          this.calculateAnalyticsFee();
        }

        const txn = this.appData.transaction;
        let totalFees = 0;
        if (txn.K_PolicyEstablishmentFee) {
          totalFees = totalFees + _.toNumber(txn.K_PolicyEstablishmentFee);
        }
        if (txn.K_AdministrationFees) {
          totalFees = totalFees + _.toNumber(txn.K_AdministrationFees);
        }
        if (this.isBrandFuse && txn.K_TotalAnalyticsFee) {
          totalFees = totalFees + _.toNumber(txn.K_TotalAnalyticsFee);
        }
        if(txn.K_PaymentMethod === 'Monthly Premium' && txn.K_MonthlyFee) {
          const totalMonthlyFees = _.toNumber(txn.K_MonthlyFee) * 12;
          totalFees = totalFees + totalMonthlyFees;
        }

        this.$set(this.appData.transaction, 'K_TotalFees', totalFees);

        if(uwPremiumOverride) {
          this.calculatePremiumTotalsUwOverride();
        }
      }
    },

    calculateAnalyticsFee: function () {
      let analyticsFee = null;
      if (this.appData && this.appData.transaction) {
        if(!isNaN(this.appData.transaction.K_NumberOfApplicableVehicles) && !isNaN(this.appData.transaction.K_FeePVPA)) {
          analyticsFee = Number(this.appData.transaction.K_NumberOfApplicableVehicles) * Number(this.appData.transaction.K_FeePVPA);
          analyticsFee = this.round(analyticsFee);
        }
      }
      this.$set(this.appData.transaction, 'K_TotalAnalyticsFee', analyticsFee);
    },

    calculatePremiumTotalsUwOverride: function() {
      if (this.appData && this.appData.transaction) {
        const txn = this.appData.transaction;

        /** Calculate Sub-Total */
        let subTotal = 0;
        if (txn.K_BasePremium) {
          subTotal = subTotal + _.toNumber(txn.K_BasePremium);
        }
        if (txn.K_EslFsl) {
          subTotal = subTotal + _.toNumber(txn.K_EslFsl);
        }
        if (txn.K_UwGst) {
          subTotal = subTotal + _.toNumber(txn.K_UwGst);
        }
        if (txn.K_StampDuty) {
          subTotal = subTotal + _.toNumber(txn.K_StampDuty);
        }

        /** Calculate Total Annual Premium */
        let totalAnnualPremium = subTotal;
        if (_.isNumber(txn.K_TotalFees)) {
          totalAnnualPremium = totalAnnualPremium + _.toNumber(txn.K_TotalFees);
          txn.K_TotalFeeGst = this.calculateGst(txn.K_TotalFees);
        }
        else {
          txn.K_TotalFeeGst = null;
        }
        if (txn.K_TotalFeeGst) {
          totalAnnualPremium = totalAnnualPremium + _.toNumber(txn.K_TotalFeeGst);
        }

        /** Calculate Net Payable to Rentsure */
        let netPayableToRentsure = totalAnnualPremium;
        if (_.isNumber(txn.K_BrokerCommission)) {
          netPayableToRentsure = netPayableToRentsure - _.toNumber(txn.K_BrokerCommission);
          // Calculate Broker-Commission-GST
          txn.K_BrokerCommissionGst = this.calculateGst(txn.K_BrokerCommission);
        }
        else {
          txn.K_BrokerCommissionGst = null;
        }
        if (txn.K_BrokerCommissionGst) {
          netPayableToRentsure = netPayableToRentsure - _.toNumber(txn.K_BrokerCommissionGst);
        }

        this.appData.transaction.K_SubTotal = subTotal.toFixed(2);
        this.appData.transaction.K_TotalAnnualPremium = totalAnnualPremium.toFixed(2);
        this.appData.transaction.K_NetPayableToRentsure = netPayableToRentsure.toFixed(2);
      }
    },

    round: function (number) {
      if(!_.isNil(number)) {
        return Math.round(Number(number) * 100 + Number.EPSILON)/100;
      }
      return null;
    },

    calculateGst: function (currencyValue) {
      if (!_.isNil(currencyValue)) {
        return this.round(Number(currencyValue) * 0.1);
      }
      return null;
    },

    showClosePageButton: function () {
      return this.isWizardReadOnly && window.opener && this.isLastPage;
    },

    closePage: function (e) {
      e.preventDefault();
      if (window.opener) {
        window.close();
      }
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        if(_.isString(errorObj)) {
          errorMsg = errorObj;
        }
        else {
          let responseStatus = _.get(errorObj, "response.status");
          if (responseStatus === 401 || (errorObj.message && errorObj.message.includes("status code 401"))) {
            errorMsg = "This session is invalid or expired. Please close this window.";
          }
          else {
            let responseResult = _.get(errorObj, "response.data.result");
            if (_.isString(responseResult)) {
              let applicationErrorPrefix = "UI_ERROR:";

              if (responseResult.startsWith(applicationErrorPrefix)) {
                errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
              }
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function () {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function () {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
  },
};
</script>
