<template>
  <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">

    <div v-if="!isBrandFuse">
      <h3 class="font-weight-bold text-dark mb-1">
        Select Business Activities
      </h3>
      <strong>
        Please select the activities that relate to the client's business. Policy inclusions and conditions that relate to
        the selected business activities will be available in the section below.</strong
      >
      <div v-if="appData && appData.transaction && appData.transaction.selectedBusinessActivities">
        <table class="table table-sm table-hover col-4">
          <thead>
          <th width="75%"></th>
          <th width="25%"></th>
          </thead>

          <tr v-for="(businessActivity, index) in appData.allBusinessActivities" :key="index">
            <td v-if="businessActivity.Name !== 'Other'" style="vertical-align: middle">
              {{ businessActivity.Name }}
            </td>
            <td v-if="businessActivity.Name !== 'Other'">
              <span class="switch switch-sm switch-outline switch-icon switch-primary">
                <label>
                  <input type="checkbox" name="selectedBusinessActivities"
                         v-model="appData.transaction.selectedBusinessActivities[businessActivity.K_BusinessActivityKey]"
                         :disabled="isReadOnly" @change="inclusionsSelectionChanged"
                  />
                  <span></span>
                </label>
              </span>
            </td>
          </tr>
        </table>
      </div>
      <!-- Hidden input to xonfirm that atleast 1 business activity is selected -->
      <ValidationProvider :rules="{ 'required-action': { allowFalse: false }}" name="atleast1BusinessActivitySelected" v-slot="{ errors }">
        <input type="number" hidden="hidden" class="form-control" v-model="atleast1BusinessActivitySelected"
               :disabled="isReadOnly"/>
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div>
      <h3 class="mt-10 mb-5 font-weight-bold text-dark">
        Review Policy Inclusions and Conditions
      </h3>

      <div v-if="appData && selectedInclusions">
        <policy-inclusions
            v-if="filteredInclusions.generalBenefits.length > 0"
            title="General Benefits / Extensions" inclusionKey="generalBenefits" expanded="false" :is-read-only="isReadOnly"
            :selected-inclusions="selectedInclusions.generalBenefits"
            :all-inclusions="filteredInclusions.generalBenefits"
            @onEditInclusion="editInclusionText"
            v-on="$listeners"
            :ignore-business-activities="isBrandFuse"
        >
        </policy-inclusions>

        <policy-inclusions
            v-if="filteredInclusions.excesses.length > 0"
            title="Excesses" inclusionKey="excesses" expanded="false" :is-read-only="isReadOnly"
            :selected-inclusions="selectedInclusions.excesses"
            :all-inclusions="filteredInclusions.excesses"
            @onEditInclusion="editInclusionText"
            v-on="$listeners"
            :ignore-business-activities="isBrandFuse"
        >
        </policy-inclusions>

        <policy-inclusions
            v-if="filteredInclusions.policyConditions.length > 0"
            title="Policy Conditions" inclusionKey="policyConditions"
            expanded="false" :is-read-only="isReadOnly"
            :selected-inclusions="selectedInclusions.policyConditions"
            :all-inclusions="filteredInclusions.policyConditions"
            @onEditInclusion="editInclusionText"
            v-on="$listeners"
            :ignore-business-activities="isBrandFuse"
        >
        </policy-inclusions>

        <h3 class="font-weight-bold text-dark">Confirmation</h3>

        <ValidationProvider :rules="{'required-action': {allowFalse:false}}" name="appData.inclusionsReviewed" v-slot="{ errors }">
        <div class="row">
          <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
            <label>
              <input type="checkbox" name="select" v-model="appData.inclusionsReviewed" :disabled="isReadOnly"
                     @input="setInclusionsChanged"
              />
              <span></span>
            </label>
          </span>
          <label class="col-form-label font-size-h5 ml-2">
            I confirm that I have reviewed the General Benefits/Extensions, Excesses and Policy conditions.
          </label>
        </div>
        <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <b-modal
            ref="editInclusionTextModal"
            :title="(isReadOnly ? 'View' : 'Edit') + ' Inclusion Text'"
            size="xl" scrollable no-close-on-backdrop hide-header-close
            @ok="saveInclusionText"
        >
          <div v-if="inclusionBeingEdited">
            <div v-if="inclusionBeingEdited.K_Comments">
              <b-card-text>Comments</b-card-text>
              <div class="form-group">
                <b-form-textarea class="form-control" v-model="inclusionBeingEdited.K_Comments" disabled rows="3"></b-form-textarea>
              </div>
            </div>

            <b-card-text>Edit the text below if required.</b-card-text>
            <div class="form-group">
              <b-form-textarea
                  class="form-control" v-model="inclusionBeingEdited.K_Text" rows="10" max-rows="20" maxlength="32000"
                  :disabled="isReadOnly"
              ></b-form-textarea>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import PolicyInclusions from "@/view/pages/wizard/newbusiness-renewal/components/PolicyInclusions.vue";

export default {
  name: "InclusionsPage",
  components: {PolicyInclusions},
  props: {
    appData: Object,
    isReadOnly: Boolean,
    selectedInclusions: Object,
    filteredInclusions: Object
  },

  data() {
    return {
      inclusionBeingEdited: null
    };
  },

  computed: {
    isBrandFuse: function () {
      return this.appData?.transaction?.K_Brand === 'Fuse';
    },

    atleast1BusinessActivitySelected: function() {
      if(this.appData && this.appData.transaction && this.appData.transaction.selectedBusinessActivities) {
        let keys = Object.keys(this.appData.transaction.selectedBusinessActivities);
        return _.some(keys, key => {
          return this.appData.transaction.selectedBusinessActivities[key] === true;
        });
      }
      return false;
    },
  },

  methods: {
    inclusionsSelectionChanged: function () {
      console.log(this.appData.transaction.selectedBusinessActivities);
      this.$emit('processInclusionsBasedOnBusinessActivities');
    },

    editInclusionText: function (inclusionKey, inclusionIndex, inclusion) {
      this.inclusionBeingEdited = _.cloneDeep(inclusion);
      this.inclusionBeingEdited.inclusionKey = inclusionKey;
      this.inclusionBeingEdited.inclusionIndex = inclusionIndex;

      // Show 'Edit Inclusion Text' modal
      this.$refs["editInclusionTextModal"].show();
    },

    setInclusionsChanged: function () {
      this.$emit('setInclusionsChanged')
    },

    saveInclusionText: function () {
      if (this.isReadOnly) {
        return;
      }

      let inclusionKey = this.inclusionBeingEdited.inclusionKey;
      let inclusionIndex = this.inclusionBeingEdited.inclusionIndex;
      delete this.inclusionBeingEdited.inclusionKey;
      delete this.inclusionBeingEdited.inclusionIndex;

      // Update the inclusions array only if the text is modified
      let oldText = this.selectedInclusions[inclusionKey][inclusionIndex].K_Text;
      let newText = this.inclusionBeingEdited.K_Text;
      if (oldText !== newText) {
        this.$set(this.inclusionBeingEdited, 'textModified', true);

        // Delete old inclusion
        this.selectedInclusions[inclusionKey].splice(inclusionIndex, 1);
        // Insert modified inclusion
        this.selectedInclusions[inclusionKey].splice(inclusionIndex, 0, this.inclusionBeingEdited);
      }
      this.inclusionBeingEdited = null;

      this.setInclusionsChanged();
    },

  }
};
</script>
