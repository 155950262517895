<template>
  <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
    <h5 v-if="fleetLookupRunning" class="text-danger">Fleet vehicles lookup in progress - please try after some time.</h5>
<!--    <h5 v-if="appData.fleetInfo && appData.fleetInfo.fleetCount === 0" class="text-danger">Fleet details not available.</h5>-->

    <button class="btn btn-outline-primary mr-5" @click.prevent="refreshFleetStatus" :disabled="appData.fleetInfo.downloadingTransactionFleet">
      Refresh Fleet Details
    </button>

    <div v-if="!fleetLookupRunning && appData.fleetInfo.fleetCount > 0" class="mt-5 mb-5">
      <h4 class="text-dark font-weight-bold">Total Fleet Size: {{appData.fleetInfo.fleetCount }} </h4>
      <h6 v-if="appData.fleetInfo.fleetCount > threshold" class="text-dark">
        Due to the large fleet size, the vehicle details are not shown below. Please download the fleet details using the button below.
      </h6>
      <button class="btn btn-primary" @click.prevent="downloadTransactionFleet" :disabled="isReadOnly || appData.fleetInfo.downloadingTransactionFleet">
        Download Fleet (CSV)
      </button>
      <b-spinner class="ml-2 align-middle" v-if="appData.fleetInfo.downloadingTransactionFleet"></b-spinner>
    </div>

    <span v-if="!isReadOnly && !fleetLookupRunning && !appData.fleetInfo.downloadingTransactionFleet">
      <fleet-upload button-title="Upload Revised Fleet" :branch-codes="branchCodes" :unique-id="appData.transaction.id"
                    :csvbox-key="refData.cblkuw" v-on="$listeners"/>
    </span>

    <div v-if="!fleetLookupRunning && appData.fleetInfo.fleetCount > 0">
      <div v-if="appData.fleetInfo.fleetCount <= threshold && Array.isArray(appData.fleetInfo.fleet)" class="mt-10">
        <ValidationObserver ref="vehiclesList">
          <vehicles-list :vehicles="appData.fleetInfo.fleet" :vehicle-types="refData.vehicleTypes" :branch-codes="branchCodes"
                         :is-read-only="isReadOnly || !editingFleet"
          />
        </ValidationObserver>

        <div v-if="!isReadOnly" class="mt-5">
          <div v-if="fleetInvalid" class="text-danger">Please fix the validation errors in the fleet above.</div>
          <button class="btn btn-primary" v-if="!editingFleet" @click.prevent="editFleet">
            Edit Fleet
          </button>
          <button class="btn btn-primary" v-if="editingFleet" @click.prevent="saveFleet">
            Save Fleet
          </button>
          <button class="btn btn-secondary ml-5" v-if="editingFleet" @click.prevent="cancelEditMode">
            Cancel
          </button>
        </div>
      </div>
    </div>


  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import VehiclesList from "@/view/pages/wizard/components/VehiclesList.vue";
import _ from 'lodash';
import FleetUpload from "@/view/pages/wizard/components/FleetUpload.vue";

export default {
  name: 'FleetPage',
  components: {FleetUpload, VehiclesList},
  props: {
    appData: Object,
    refData: Object,
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      editingFleet: false,
      fleetCopy: null,
      fleetInvalid: false
    };
  },

  computed: {
    fleetLookupRunning: function () {
      const formFleetInfoRunning = _.get(this.appData, 'form.K_FleetLookupRunning') || false;
      const transactionFleetInfoRunning = _.get(this.appData, 'fleetInfo.K_FleetLookupRunning') || false;

      return formFleetInfoRunning || transactionFleetInfoRunning;
    },

    branchCodes: function() {
      let branchCodes = [];
      if(this.appData && Array.isArray(this.appData.clientBranches)) {
        console.log(this.appData.clientBranches)
        branchCodes = _.map(this.appData.clientBranches, 'K_BranchCode');
      }
      return branchCodes;
    },

    threshold: function () {
      return this?.appData?.fleetInfo?.fleetUiThreshold;
    }
  },

  methods: {
    refreshFleetStatus: function () {
      this.$emit('refreshFleetStatus');
    },

    downloadTransactionFleet: function () {
      this.$emit('downloadTransactionFleet');
    },

    editFleet: function () {
      this.fleetInvalid = false;
      this.fleetCopy = _.cloneDeep(this.appData.fleetInfo.fleet);
      this.editingFleet = true;
    },

    saveFleet: async function () {
      const validFleet = await this.$refs.vehiclesList.validate();
      this.fleetInvalid = !validFleet;
      if(this.fleetInvalid) {
        return;
      }

      this.$emit('saveTransactionFleet', this.appData.fleetInfo.fleet);
      this.fleetCopy = null;
      this.editingFleet = false;
    },

    cancelEditMode: function () {
      this.fleetInvalid = false;
      this.$set(this.appData, 'fleetInfo.fleet', this.fleetCopy);
      this.fleetCopy = null;
      this.editingFleet = false;
    }
  }
};
</script>
