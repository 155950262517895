<template>
  <ValidationObserver ref="wizardPage5" id="wizardPage5" name="wizardPage5">
    <div>
      <div v-if="appData &&appData.policy && appData.client && appData.transaction && appData.documents">
        <Document
            document-name="Quote Document"
            :document="appData.documents.quoteDocument"
            :on-create-document="createQuoteDocument"
            :processing="uiDocs.quoteDocument.processing"
            :mailto-link="quoteDocumentMailtoLink"
            :is-read-only="isReadOnly"
        />

        <div v-if="!isReadOnly && appData.documents.quoteDocument">
          <br />
          <h3 class="font-weight-bold text-dark mb-2 mt-5">
            Send Quote to Client
          </h3>
          <strong>
            Please review the document, create a PDF version, and place it in the 'Client Shared' Box folder.<br />
            Then click the 'Email Document' button to send it to the client via email (Outlook), and confirm once this has been done.
          </strong>

          <div class="row">
            <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
              <label>
                <input type="checkbox" name="select" v-model="appData.quoteDocumentSent" :disabled="isReadOnly"/>
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2">I confirm that the quote document has been sent to the client.</label>
          </div>
          <br />
        </div>

        <div v-if="!isReadOnly && appData.quoteDocumentSent">
          <h3 class="font-weight-bold text-dark mb-2 mt-5">
            Finalisation
          </h3>
          <strong>
            Choose one of the options below and enter the required information. Then click on 'Complete Transaction' button.
          </strong>
          <br /><br />

          <b-form-radio-group v-model="tempOutcome" name="quoteDecision" class="form-control mb-5 mt-3" size="lg" @change="setOutcome">
            <b-form-radio :value="outcomes.acceptedByClient">Accepted by Client</b-form-radio>
            <b-form-radio class="ml-10" :value="outcomes.rejectedByClient">Rejected by Client</b-form-radio>
          </b-form-radio-group>

          <div v-if="outcome === outcomes.acceptedByClient">
            <div class="form-group row mb-0">
              <label class="col-3 col-form-label text-right">Policy Number</label>
              <div class="col-3 font-weight-bold col-form-label">
                {{ appData.policy.K_PolicyNumber }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-3 col-form-label text-right">iBais Client Number</label>
              <div class="col-3 font-weight-bold col-form-label">
                {{ appData.client.K_IbaisClientNumber }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-3 col-form-label text-right">Insurance Start Date</label>
              <div class="col-3 font-weight-bold col-form-label">
                {{ appData.transaction.K_ProposedStartDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-3 col-form-label text-right">Insurance End Date</label>
              <div class="col-3 font-weight-bold col-form-label">
                {{ appData.transaction.K_ProposedEndDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-3 col-form-label text-right font-weight-bold font-size-h6">
                Enter iBais Memo Number
              </label>
              <div class="col-3">
                <input type="text" size="255" class="form-control text-uppercase" v-model="tempIbaisMemoNumber"
                       @input="setMemoNumber" :disabled="isRenewalTransaction && ibaisMemoNumber"
                />
              </div>
            </div>

          </div>

          <div v-if="outcome === outcomes.rejectedByClient">
            <div class="form-group row mb-0">
              <div class="col-12">
                <label class="col-form-label font-weight-bold font-size-h6">Enter Rejection Reason</label>
                <b-form-textarea class="form-control" size="2000" rows="3" max-rows="6" v-model="appData.transaction.K_Reason" :disabled="isReadOnly"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isReadOnly">
          <h3 class="font-weight-bold text-dark mb-5 mt-10">
            {{ appData.transaction.K_Type }} Transaction Finalised
          </h3>

          <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold">
            <!-- Transaction accepted by client - show policy details -->
            <div v-if="outcome === outcomes.acceptedByClient">
              <b-card-text>
                <strong>Details of the finalised transaction are below.</strong>
              </b-card-text>
              <div class="form-group row mb-0 mt-2">
                <label class="col-3 col-form-label text-right">
                  Insurance Start Date
                </label>
                <div class="col-3 font-weight-bold col-form-label">
                  {{ appData.transaction.K_ProposedStartDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                </div>
                <label class="col-3 col-form-label text-right">Total Annual Premium</label>
                <div class="col-3 font-weight-bold col-form-label">
                  {{ appData.transaction.K_TotalAnnualPremium | currency }}
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-3 col-form-label text-right">Insurance End Date</label>
                <div class="col-3 font-weight-bold col-form-label">
                  {{ appData.transaction.K_ProposedEndDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                </div>

                <label class="col-3 col-form-label text-right">Excess</label>
                <div class="col-3 font-weight-bold col-form-label" v-if="appData.transaction.K_RequiredExcess">
                  {{ Number(appData.transaction.K_RequiredExcess) | currency}}
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-3 col-form-label text-right">Premium Type</label>
                <div class="col-3 font-weight-bold col-form-label">
                  {{ appData.transaction.K_PaymentMethod }}
                </div>
              </div>
            </div>

            <!-- Transaction rejected by client - show reason -->
            <div v-if="outcome === outcomes.rejectedByClient">
              <b-card-text><strong>Outcome : {{ outcome }}</strong></b-card-text>
              <div class="form-group row mb-0">
                <div class="col-12">
                  <label class="col-form-label font-weight-bold font-size-h6">Rejection Reason</label>
                  <b-form-textarea class="form-control" size="2000" rows="3" max-rows="6" v-model="appData.transaction.K_Reason" disabled/>
                </div>
              </div>
            </div>

          </b-card>
        </div>

        <div v-if="isRenewalTransaction && isQuoteAcceptedByClient && isReadOnly" class="mb-10">
          <h3 class="font-weight-bold text-dark mb-5 mt-10">
            Fleet Upload
          </h3>

          <label class="font-weight-bold mb-5">
            Please upload the new fleet details for the renewed policy.
          </label>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import Document from "@/view/pages/wizard/newbusiness-renewal/components/Document.vue";

export default {
  name: "FinalisationPage",
  components: { Document },
  props: {
    appData: Object,
    outcome: String,
    isReadOnly: Boolean,
    ibaisMemoNumber: String
  },

  data() {
    return {
      tempIbaisMemoNumber: this.ibaisMemoNumber,
      tempOutcome: this.outcome,

      uiDocs: {
        quoteDocument: {
          docType: "quoteDocument",
          docgenDocType: "QUOTATION",
          processing: false,
        },
      },

      outcomes: {
        acceptedByClient: "Accepted by Client",
        rejectedByClient: "Rejected by Client",
        declinedByInsurer: "Declined by Insurer",
      },
    };
  },

  watch: {
    ibaisMemoNumber: function(newVal /*, oldVal*/) {
      this.tempIbaisMemoNumber = newVal;
    }
  },

  computed: {
    quoteDocumentMailtoLink: function () {
      let mailTo = null;

      if (this.appData && this.appData.form && this.appData.client) {
        let form = this.appData.form;
        let client = this.appData.client;

        let brand = form.K_Brand;
        let emailAddress = null;
        let firstName = null;
        if (brand === "CRI") {
          emailAddress = _.trim(form.Secondary_Email);
          firstName = _.trim(form.K_FirstName);
        } else {
          emailAddress = _.trim(form.Email);
          firstName = _.trim(form.K_ClientContactFirstName);
        }

        let clientName = _.trim(form.K_ClientNamePerForm);
        let clientSharedFolderLink = client.K_ClientSharedBoxFolderLink;

        if (brand &&
            emailAddress &&
            firstName &&
            clientName &&
            clientSharedFolderLink
        ) {
          // Create mailto link
          mailTo = `mailto:${emailAddress}`;

          // Add subject
          let subject = `${brand} Motor & Fleet Quotation - ${clientName} - ${new Date().getFullYear()}`;
          subject = encodeURIComponent(subject);
          mailTo = `${mailTo}?subject=${subject}`;

          // Add email body
          let emailBody =
              `Dear ${firstName},%0D%0A %0D%0A` +
              `Thank you for the opportunity to provide a quotation.%0D%0A %0D%0A` +
              `We are pleased to provide you with a motor vehicle insurance quote based on the information you have ` +
              `provided.%0D%0A %0D%0A` +
              `Also find attached the quote, product disclosure statement and the financial services guide for you to review.%0D%0A %0D%0A` +
              `To access these documents online, please click on the link below.%0D%0A %0D%0A` +
              `${clientSharedFolderLink} %0D%0A%0D%0A` +
              `Our office will reach out in a few days to follow up and discuss.%0D%0A %0D%0A` +
              `Kind regards,%0D%0A`;
          mailTo = `${mailTo}&body=${emailBody}`;
        }
      }
      return mailTo;
    },

    isQuoteAcceptedByClient: function () {
      return this.outcome === "Accepted by Client";
    },

    isRenewalTransaction: function () {
      if(this.appData) {
        let txnType = _.get(this.appData, 'transaction.K_Type');
        return txnType === "Renewal";
      }
      return false;
    }
  },

  methods: {
    setOutcome: function() {
      this.$emit('setOutcome', this.tempOutcome);
    },

    setMemoNumber: function() {
      this.$emit('setMemoNumber', this.tempIbaisMemoNumber);
    },

    createQuoteDocument: function () {
      this.$emit('createQuoteDocument', this.uiDocs.quoteDocument);
    },
  }
};
</script>
