<template>
  <div v-if="approval">
    <div class="form-group row mt-2 mb-2" v-if="!isReadOnly">
      <div class="col-12">
        <b-form-file
            multiple
            class="form-control" v-model="approval.selectedFiles"
            placeholder="Drag-and-drop files here, or click Browse to select files"
        >
        </b-form-file>
        <b-button size="sm" class="mt-2" @click.prevent="clearSelectedFiles">Clear Selected Files</b-button>
      </div>
    </div>

    <label class="col-form-label text-left mt-2 text-danger" v-if="errorMessage">
      <strong>{{ errorMessage }}</strong>
    </label>

    <div v-if="Array.isArray(approval.attachments) && approval.attachments.length > 0">
      <label class="col-form-label text-left mt-2">
        <strong>Attachments:</strong>
      </label>
      <div class="form-group mb-1">
        <table class="table table-sm col-8 table-bordered">
          <tr v-for="(attachment, index) in approval.attachments" :key="index">
            <td width="50%"><a :href="attachments.link" target="_blank">{{ attachments.name }}</a></td>
            <td width="15%">{{ formatSize(attachment.size) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";

export default {
  name: "ApprovalAttachments",

  props: {
    approval: Object,
    isReadOnly: Boolean
  },
 
  data() {
    return {
      errorMessage: null
    };
  },
  
  methods: {
    clearSelectedFiles: function() {
      this.approval.selectedFiles.splice(0, this.approval.selectedFiles.length);
    },

    formatSize: function (bytes) {
      if(_.isNil(bytes)) {
        return "";
      }

      if (bytes < 1024) return bytes + " Bytes";
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + " KB";
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + " MB";
      else return (bytes / 1073741824).toFixed(2) + " GB";
    }
  }
};
</script>
