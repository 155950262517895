<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div>
      <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold">
        <!-- FORM NOT ACCEPTED YET -->
        <div v-if="!isFormAccepted && !isFormVoided">
          <h3 class="mb-3 font-weight-bold text-dark">
            {{ appData.form.K_FormType || 'Motor Proposal Form' }}
          </h3>

          <a :href="proposalFormLink" target="_blank">
            <strong>Click here to view the submitted form</strong>
            <i class="ml-2 fas fa-external-link-alt fa-lg"></i>
          </a>
          <br/><br/>

          <div v-if="loadingComparisonData" class="mb-5">
            <span class="row">
              <b-spinner variant="primary"></b-spinner>
              <span class="ml-2 pt-1">Checking Form Data...</span>
            </span>
          </div>

          <div v-if="isComparisonDataValid" class="mb-5">
            <span class="text-danger font-size-h3 font-weight-bold">Warning - Found mismatch between CRM data and Form data.</span>

            <div v-if="comparisonData.protectedData.length > 0" class="mt-5">
              <span class="text-danger">
                <h5>Following CRM Data is protected, and will NOT be overwritten when the form is accepted.</h5>
              </span>

              <table class="table table-bordered">
                <th style="text-align: center;">Field</th>
                <th style="text-align: center;">Unique ID</th>
                <th style="text-align: center;">CRM Data</th>
                <th style="text-align: center;">Form Data</th>

                <tr v-for="(dataRow, index) in comparisonData.protectedData" :key="index">
                  <td>{{ dataRow.displayName }}</td>
                  <td>{{ dataRow.uniqueId }}</td>
                  <td>{{ dataRow.crmValue }}</td>
                  <td>{{ dataRow.formValue }}</td>
                </tr>
              </table>
            </div>

            <div v-if="comparisonData.unprotectedData.length > 0" class="mt-10">
              <span class="text-danger">
                <h5>Following CRM Data WILL BE OVERWRITTEN when the form is accepted.</h5>
              </span>

              <table class="table table-bordered">
                <th style="text-align: center;">Field</th>
                <th style="text-align: center;">CRM Data</th>
                <th style="text-align: center;">Form Data</th>

                <tr v-for="(dataRow, index) in comparisonData.unprotectedData" :key="index">
                  <td>{{ dataRow.displayName }}</td>
                  <td>{{ dataRow.crmValue }}</td>
                  <td>{{ dataRow.formValue }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="mt-10">
            <button type="button" class="col-4 btn btn-primary font-weight-bold"
                    @click.prevent="confirmFormAcceptance" :disabled="loadingComparisonData"
            >
              Accept Form
            </button>
            <a v-if="!isFormForRenewal"
               target="_blank" class="col-4 btn btn-outline-primary kt-font-bold ml-5"
               :href="formResendMailtoLink"
            >
              Re-send to Client for more info
            </a>
            <button type="button" class="col-3 btn btn-outline-danger font-weight-bold ml-5"
                    @click.prevent="confirmFormVoid" :disabled="loadingComparisonData"
            >
              Void Form
            </button>
          </div>

          <b-modal
              ref="acceptFormModal" title="Accept Form" size="md" no-close-on-backdrop hide-header-close
              @ok="acceptForm" @cancel="resetFormModals"
          >
            <div>
              <b-card-text>Add or update the Client information in
                <strong><a href="https://ibais.com.au/ibais" target="_blank">iBais</a></strong>, and then enter the information below.
              </b-card-text>
              <div class="form-group row mb-0">
                <label class="col-6 col-form-label text-right"><strong>Client Name</strong></label>
                <label class="col-6 col-form-label"><strong>{{ appData.form.K_ClientNamePerForm }}</strong></label>
              </div>
              <div class="form-group row mb-0">
                <label class="col-6 col-form-label text-right"><strong>Enter iBais Client Number</strong></label>
                <div class="col-6">
                  <input type="text" size="255" class="form-control text-uppercase" v-model="appData.form.K_IbaisClientNumber" :disabled="isFormForRenewal"/>
                </div>
              </div>

              <div v-if="isFormForRenewal" class="form-group row mb-0 mt-2">
                <label class="col-6 col-form-label text-right"><strong>iBais Memo Number</strong></label>
                <div class="col-6">
                  <input type="text" size="255" class="form-control" v-model="ibaisMemoNumber" disabled/>
                </div>
              </div>
              <b-card-text class="text-danger mt-3" v-if="formModalError">{{ formModalError }}</b-card-text>
            </div>
          </b-modal>

          <b-modal ref="voidFormModal" title="Void Form" size="md" no-close-on-backdrop hide-header-close
                   @ok="voidForm" @cancel="resetFormModals"
          >
            <div>
              <b-card-text>Enter the reason for voiding the form:</b-card-text>
              <div class="form-group">
                <ValidationProvider rules="required" name="voidReason" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="voidReason" :disabled="isReadOnly">
                    <option></option>
                    <option value="Outside of risk appetite - caravans">Outside of risk appetite - caravans</option>
                    <option value="Outside of risk appetite - courier">Outside of risk appetite - courier</option>
                    <option value="Outside of risk appetite - high-end / prestige">Outside of risk appetite - high-end / prestige</option>
                    <option value="Below minimum premium">Below minimum premium</option>
                    <option value="Level of cover">Level of cover</option>
                    <option value="No response from client/broker">No response from client/broker</option>
                    <option value="Excess level">Excess level</option>
                    <option value="Multiple enquiries on same risk">Multiple enquiries on same risk</option>
                    <option value="Moved to CRI">Moved to CRI</option>
                    <option value="Moved to Rentsure">Moved to Rentsure</option>
                    <option value="Poor claims history">Poor claims history</option>
                    <option value="Had previous not suitable client">Had previous not suitable client</option>
                    <option value="Insufficient detail to quote">Insufficient detail to quote</option>
                    <option value="Existing client">Existing client</option>
                    <option value="Other">Other</option>
                  </b-form-select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <a v-if="!isFormForRenewal" target="_blank" class="col-4 btn btn-outline-danger kt-font-bold mt-5"
                   :href="formVoidMailtoLink"
                >
                  Send Decline Email
                </a>
              </div>

              <b-form-group class="mb-0" v-if="!isFormForRenewal">
                <b-form-checkbox size="lg" v-model="createCrmRecordsForVoidedForm">
                  <strong>Create Client record, Box folders, and related records in the CRM</strong>
                </b-form-checkbox>
              </b-form-group>

              <div class="row form-group mb-0" v-if="createCrmRecordsForVoidedForm && !isFormForRenewal">
                <label class="col-5 col-form-label text-right">iBais Client Number</label>
                <div class="col-6">
                  <input type="text" size="255" class="form-control" v-model="appData.form.K_IbaisClientNumber"/>
                </div>
              </div>

              <b-card-text class="text-danger mt-3" v-if="formModalError">
                {{ formModalError }}
              </b-card-text>
            </div>
          </b-modal>
        </div>

        <!-- FORM ALREADY PROCESSED - REVIEW CLIENT AND PROPOSAL -->
        <div v-if="isFormAccepted && appData.client && this.appData.proposalQuestions">
          <h3 class="mb-5 font-weight-bold text-dark">
            Review Client and Proposal Details
          </h3>

          <strong>Please perform the steps below, and then mark them as completed.</strong>
          <table class="table table-hover table-striped col-12">
            <thead>
              <th width="10%"></th>
              <th width="30%"></th>
              <th width="40%"></th>
              <th width="10%"></th>
              <th width="10%"></th>
            </thead>
            <tr>
              <td><i class="fas fa-user-check text-primary fa-2x"></i></td>
              <td><b>Verify Client's ABN</b></td>
              <td>{{ appData.client.K_Abn }}</td>
              <td><a v-if="abnLink" :href="abnLink" target="_blank"><i class="fas fa-external-link-alt fa-lg"></i></a></td>
              <td>
                <ValidationProvider :rules="{'required-action': {allowFalse:false}}" name="appData.proposalQuestions.verifyAbn" v-slot="{ errors }">
                <span class="switch switch-sm switch-outline switch-icon switch-primary">
                  <label>
                    <input type="checkbox" v-model="appData.proposalQuestions.verifyAbn" :disabled="isReadOnly"/>
                    <span></span>
                  </label>
                </span>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td><i class="fas fa-map-marker-alt text-primary fa-2x"></i></td>
              <td><b>Check Client location</b></td>
              <td>{{ getClientAddress() }}</td>
              <td>
                <a v-if="getClientAddressMapLink()" :href="getClientAddressMapLink()" target="_blank">
                  <i class="fas fa-external-link-alt fa-lg"></i>
                </a>
              </td>
              <td>
                <ValidationProvider :rules="{'required-action': {allowFalse:false}}" name="appData.proposalQuestions.checkLocation" v-slot="{ errors }">
                <span class="switch switch-sm switch-outline switch-icon switch-primary">
                  <label>
                    <input type="checkbox" v-model="appData.proposalQuestions.checkLocation" :disabled="isReadOnly"/>
                    <span></span>
                  </label>
                </span>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td><i class="fab fa-wpforms text-primary fa-2x"></i></td>
              <td><b>Review the Proposal Form</b></td>
              <td></td>
              <td>
                <a :href="proposalFormLink" target="_blank"><i class="fas fa-external-link-alt fa-lg"></i></a>
              </td>
              <td>
                <ValidationProvider :rules="{'required-action': {allowFalse:false}}" name="appData.proposalQuestions.reviewProposalForm" v-slot="{ errors }">
                <span class="switch switch-sm switch-outline switch-icon switch-primary">
                  <label>
                    <input type="checkbox" v-model="appData.proposalQuestions.reviewProposalForm" :disabled="isReadOnly"/>
                    <span></span>
                  </label>
                </span>
                <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </table>
        </div>

        <!-- FORM IS VOIDED -->
        <div v-if="isFormVoided">
          <b-card-text class="mb-10 font-weight-bold text-dark">
            This form is in <strong>voided</strong> status. If you wish to re-activate this form, please use the button
            below. Otherwise, please close this window.
          </b-card-text>

          <button type="button" class="col-3 btn btn-outline-danger font-weight-bold" @click.prevent="confirmFormUnvoid">
            Re-activate Form
          </button>

          <b-modal
              ref="unvoidFormModal" title="Re-activate Form" size="md" no-close-on-backdrop hide-header-close
              @ok="unvoidForm" @cancel="resetFormModals"
          >
            <div>
              <b-card-text>Enter the reason for re-activating the form:</b-card-text>
              <div class="form-group mb-0">
                <b-form-textarea size="2000" class="form-control" v-model="unvoidReason"/>
              </div>
              <b-card-text class="text-danger mt-3" v-if="formModalError">{{ formModalError }}</b-card-text>
            </div>
          </b-modal>
        </div>
      </b-card>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";

export default {
  name: "ReviewPage",
  components: {},
  props: {
    appData: Object,
    comparisonData: Object,
    loadingComparisonData: Boolean,
    ibaisMemoNumber: null,
    isReadOnly: Boolean
  },

  data() {
    return {
      formModalError: null,
      voidReason: null,
      unvoidReason: null,
      createCrmRecordsForVoidedForm: null
    };
  },

  computed: {
    isFormAccepted: function () {
      if(this.appData) {
        let formStatus = _.get(this.appData, 'form.K_Status');
        return formStatus === "Accepted";
      }
      return false;
    },

    isFormVoided: function () {
      if(this.appData) {
        let formStatus = _.get(this.appData, 'form.K_Status');
        return formStatus === "Voided";
      }
      return false;
    },

    isFormForRenewal: function() {
      if(this.appData) {
        let formType = _.get(this.appData, 'form.K_FormType');
        return formType === "Renewal Declaration Form";
      }
      return false;
    },

    isRenewalTransaction: function () {
      if(this.appData) {
        let txnType = _.get(this.appData, 'transaction.K_Type');
        return txnType === "Renewal";
      }
      return false;
    },

    proposalFormLink: function () {
      if (this.appData && this.appData.form) {
        return this.appData.form.K_ViewLink;
      }
      return null;
    },

    abnLink: function () {
      let abnLink = null;
      if (this.appData && this.appData.client && this.appData.client.K_Abn) {
        let abn = this.appData.client.K_Abn;
        abn = abn.replace(/\s+/g, "");
        abnLink = `https://abr.business.gov.au/ABN/View?id=${abn}`;
      }
      return abnLink;
    },

    isComparisonDataValid: function () {
      return this.comparisonData &&
          ((Array.isArray(this.comparisonData.protectedData) && this.comparisonData.protectedData.length > 0) ||
              (Array.isArray(this.comparisonData.unprotectedData) && this.comparisonData.unprotectedData.length > 0)
          );
    },

    formResendMailtoLink: function () {
      let mailTo = null;

      if (this.appData && this.appData.form) {
        let form = this.appData.form;

        let brand = form.K_Brand;
        let emailAddress = null;
        let firstName = null;
        if (brand === "CRI") {
          emailAddress = _.trim(form.Secondary_Email);
          firstName = _.trim(form.K_FirstName);
        } else {
          emailAddress = _.trim(form.Email);
          firstName = _.trim(form.K_ClientContactFirstName);
        }

        let formEditLink = form.K_EditLink;
        let clientName = _.trim(form.K_ClientNamePerForm);

        if (brand && emailAddress && firstName && formEditLink && clientName) {
          // Create mailto link
          mailTo = `mailto:${emailAddress}`;

          // Add subject
          let subject = `Clarification required on your ${brand} Proposal Form`;
          subject = encodeURIComponent(subject);
          mailTo = `${mailTo}?subject=${subject}`;

          // Add email body
          let emailBody =
              `Dear ${firstName},%0D%0A %0D%0A` +
              `Thank you for submitting a Motor Proposal form for ${clientName}. We require some additional information ` +
              `from you in order to prepare a formal quote. %0D%0A %0D%0A` +
              `To access the form, please click the following link: %0D%0A %0D%0A` +
              `${formEditLink} %0D%0A%0D%0A` +
              `If you have any questions about the form, please don’t hesitate to contact me.%0D%0A %0D%0A` +
              `Kind regards,%0D%0A`;

          mailTo = `${mailTo}&body=${emailBody}`;
        }
      }

      return mailTo;
    },

    formVoidMailtoLink: function () {
      let mailTo = null;

      if (this.appData && this.appData.form) {
        let form = this.appData.form;

        let brand = form.K_Brand;
        let emailAddress = null;
        let firstName = null;
        if (brand === "CRI") {
          emailAddress = _.trim(form.Secondary_Email);
          firstName = _.trim(form.K_FirstName);
        } else {
          emailAddress = _.trim(form.Email);
          firstName = _.trim(form.K_ClientContactFirstName);
        }

        let clientName = _.trim(form.K_ClientNamePerForm);

        if (brand && emailAddress && firstName && clientName) {
          // Create mailto link
          mailTo = `mailto:${emailAddress}`;

          // Add subject
          let subject = `${brand} Motor Proposal Form - ${clientName} - ${new Date().getFullYear()}`;
          subject = encodeURIComponent(subject);
          mailTo = `${mailTo}?subject=${subject}`;

          // Add email body
          let emailBody =
              `Dear ${firstName},%0D%0A %0D%0A` +
              `Thank you for submitting a Motor Proposal form for ${clientName}. Unfortunately this risk is outside ` +
              `our agency appetite, and therefore we have declined to quote. %0D%0A %0D%0A` +
              `If you have any questions about the form, please don't hesitate to contact me.%0D%0A %0D%0A` +
              `Kind regards,%0D%0A`;

          mailTo = `${mailTo}&body=${emailBody}`;
        }
      }

      return mailTo;
    }
  },

  methods: {
    confirmFormAcceptance: function () {
      this.$refs["acceptFormModal"].show();
    },

    acceptForm: function (bvModalEvent) {
      this.formModalError = null;
      if (!this.appData.form.K_IbaisClientNumber) {
        this.formModalError = "Please enter iBais Client Number.";
        bvModalEvent.preventDefault();
        return;
      }

      if (this.isFormForRenewal && !this.ibaisMemoNumber) {
        this.formModalError = "iBais Memo Number is mandatory for the Renewal.";
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit('acceptForm');
    },

    confirmFormVoid: function () {
      this.$refs["voidFormModal"].show();
    },

    voidForm: function (bvModalEvent) {
      this.formModalError = null;
      if (!this.voidReason || (this.createCrmRecordsForVoidedForm && !this.appData.form.K_IbaisClientNumber)) {
        this.formModalError = "Please enter the required information";
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit('voidForm', this.voidReason, this.createCrmRecordsForVoidedForm);
    },

    confirmFormUnvoid: function () {
      this.$refs["unvoidFormModal"].show();
    },

    unvoidForm: function (bvModalEvent) {
      this.formModalError = null;
      if (!this.unvoidReason) {
        this.formModalError = "Please enter the required information";
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit('unvoidForm', this.unvoidReason);
    },

    resetFormModals: function () {
      this.formModalError = null;
      this.voidReason = null;
      this.unvoidReason = null;
      this.createCrmRecordsForVoidedForm = null;
    },

    getClientAddress: function () {
      let address = "";
      if (this.appData && this.appData.client) {
        let client = this.appData.client;

        if (client.K_Address) {
          address = address + client.K_Address + " ";
        }
        if (client.K_Address2) {
          address = address + client.K_Address2 + " ";
        }
        if (client.K_City) {
          address = address + client.K_City + " ";
        }
        if (client.K_Postcode) {
          address = address + client.K_Postcode + " ";
        }
        address = address.trim();
      }
      return address;
    },

    getClientAddressMapLink: function () {
      let address = this.getClientAddress();
      if (address) {
        return "https://maps.google.com/?q=" + address.replace(/ /g, "+");
      }
      return null;
    },

  }
};
</script>
