<template>
  <div class="mb-10">
    <div class="mb-2 font-weight-bold text-dark font-size-lg">
      <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'panel_' + panelId">
        <img v-if="!isExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand"/>
        <img v-if="isExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse"/>
      </b-button>
      {{ title }}
    </div>

    <b-collapse v-model="isExpanded" :id="'panel_' + panelId">
      <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white"
              header-class="font-size-lg font-weight-bold" body-class="p-3">
        <b-alert v-if="deletedInclusions.length > 0" variant="warning" show>
          <div class="text-dark">
            Some {{ title }} have been deleted: <br />
            <div v-for="(deletedInclusion, index) in deletedInclusions" :key="index">
              <li>{{ deletedInclusion.K_Title }}</li>
            </div>
          </div>
        </b-alert>

        <label class="col-form-label font-weight-bold font-size-lg pb-0">
          Selected {{ title }}:
        </label>
        <table class="table table-sm table-striped table-hover">
          <thead>
            <th width="40%" class="text-center">Title</th>
            <th v-if="!ignoreBusinessActivities" width="20%" class="text-center">Business Activity</th>
            <th width="5%" class="text-center">Order</th>
            <th width="10%" class="text-center">Text Modified</th>
            <th width="20%" class="text-center">Actions</th>
          </thead>
          <tbody>
            <tr v-for="(inclusion, index) in selectedInclusions" :key="inclusion.Name">
              <td>
                <label class="col-form-label"> {{ inclusion.K_Title }}</label>
              </td>
              <td class="text-center" v-if="!ignoreBusinessActivities">
                <label class="col-form-label">{{ inclusion.K_BusinessActivity ? inclusion.K_BusinessActivity.name : ''}}</label>
              </td>
              <td class="text-center">
                <label class="col-form-label">{{ inclusion.K_SectionOrder }}</label>
              </td>
              <td class="text-center">
                <b-badge variant="warning" class="mt-2">{{ inclusion.textModified ? "Yes" : "" }}</b-badge>
              </td>
              <td class="text-center">
                <button type="button" class="btn btn-sm btn-outline-primary" @click="editInclusion(index)">
                  {{ isReadOnly ? "View" : "Edit" }} Text
                </button>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-outline-danger ml-1"
                        @click.prevent="deleteFromSelectedList(index)">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-10" v-if="allInclusionsMinusSelected.length > 0">
          <b-card-text class="mb-0"
            ><b
              >To select more inclusions, select one or more options from the
              drop-down list and click 'Add' button.</b
            ></b-card-text
          >

          <div class="row mb-0">
            <div class="form-group col-10">
              <v-select
                v-model="tempArray"
                :items="allInclusionsMinusSelected"
                :item-text="getItemTitle"
                return-object
                multiple
                chips
                deletable-chips
                clearable
                :disabled="isReadOnly"
              ></v-select>
            </div>
            <div class="form-group col-2">
              <button
                class="btn btn-primary btn-block ml-1"
                v-on:click.prevent="addToSelectedList"
                :disabled="isReadOnly"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4";
</style>

<script>
import _ from "lodash";

export default {
  name: "PolicyInclusions",
  props: {
    title: String,
    inclusionKey: String,
    allInclusions: Array,
    selectedInclusions: Array,
    expanded: String,
    ignoreBusinessActivities: Boolean,
    isReadOnly: Boolean
  },
  data() {
    return {
      isExpanded: null,
      tempArray: [],
      selectedInclusionsCopy: []
    };
  },
  mounted() {
    this.isExpanded = this.expanded !== "false";
  },

  computed: {
    panelId: function() {
      return this.title
        .replace(/\W/g, "")
        .trim()
        .toLowerCase();
    },

    allInclusionsMinusSelected: function() {
      let filteredInclusions = [];
      if (Array.isArray(this.allInclusions) && this.allInclusions.length > 0) {
        filteredInclusions = _.filter(this.allInclusions, inclusion => {
          return !this.isAlreadySelected(inclusion);
        });
      }
      return filteredInclusions;
    },

    deletedInclusions: function() {
      let nonOptionalDeletedInclusions = [];
      if (
        Array.isArray(this.allInclusionsMinusSelected) &&
        this.allInclusionsMinusSelected.length > 0
      ) {
        nonOptionalDeletedInclusions = _.filter(
          this.allInclusionsMinusSelected,
          inclusion => {
            return !inclusion.K_Optional;
          }
        );
      }
      return nonOptionalDeletedInclusions;
    }
  },

  methods: {
    getItemTitle: function(item) {
      let title = item.K_Title;
      if(!this.ignoreBusinessActivities && item.K_BusinessActivity) {
        title = `${title} (${item.K_BusinessActivity.name})`;
      }
      return title;
    },

    addToSelectedList: function() {
      if (Array.isArray(this.tempArray) && this.tempArray.length > 0) {
        this.selectedInclusionsCopy = this.selectedInclusions || [];

        // Add to selectedInclusionsCopy array if not already present
        this.tempArray.forEach(incl => {
          if (!this.isAlreadySelected(incl)) {
            this.selectedInclusionsCopy.push(incl);
          }
        });

        // Sort by business-activity-name and section-order
        for (let incl of this.selectedInclusionsCopy) {
          if (incl.K_BusinessActivity) {
            incl.BusinessActivityName = incl.K_BusinessActivity.name;
          }
        }

        this.selectedInclusionsCopy = _.orderBy(
          this.selectedInclusionsCopy,
          ["BusinessActivityName", "K_SectionOrder"],
          ["asc", "asc"]
        );

        // Notify the parent component
        this.notifyDataUpdate();
      }
      this.tempArray = [];
    },

    deleteFromSelectedList: function(index) {
      if (Array.isArray(this.selectedInclusions) && this.selectedInclusions.length > 0) {
        this.selectedInclusionsCopy = this.selectedInclusions;
        this.selectedInclusionsCopy.splice(index, 1);

        // Notify the parent component
        this.notifyDataUpdate();
      }
    },

    isAlreadySelected: function(inclusion) {
      if (Array.isArray(this.selectedInclusions) && this.selectedInclusions.length > 0) {
        return _.some(this.selectedInclusions, { Name: inclusion.Name });
      }
      return false;
    },

    notifyDataUpdate: function() {
      this.$emit("onUpdate", this.inclusionKey, this.selectedInclusionsCopy);
    },

    editInclusion: function(index) {
      let toBeEdited = this.selectedInclusions[index];
      this.$emit("onEditInclusion", this.inclusionKey, index, toBeEdited);
    }
  }
};
</script>
