<template>
  <div>
    <h3 class="font-weight-bold text-dark mb-4">
      {{ documentName }}
    </h3>

    <div v-if="!document">
      <div class="form-text kt-font-regular mb-2">
        {{ helpText }}
      </div>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        :disabled="isReadOnly || processing"
        v-on:click.prevent="onCreateDocument"
      >
        Create {{ documentName }}
      </button>
      <b-spinner
        v-if="processing"
        class="ml-3"
        variant="primary"
        label="Please wait..."
      ></b-spinner>
    </div>

    <div v-if="document">
      <div class="form-group">
        <a
          target="_blank"
          class="btn btn-primary kt-font-bold ml-0"
          v-bind:href="document.view_url"
        >
          View Document
        </a>
        <a
          target="_blank"
          class="btn btn-primary kt-font-bold ml-5"
          v-bind:href="document.download_url"
        >
          Download Document
        </a>
        <a
          v-if="mailtoLink && !isReadOnly"
          class="btn btn-primary kt-font-bold ml-5"
          v-bind:href="mailtoLink"
        >
          Email Document
        </a>
        <button
          type="button"
          class="btn btn-outline-primary font-weight-bold ml-7"
          v-on:click.prevent="onCreateDocument"
          v-if="!isReadOnly"
          :disabled="processing"
        >
          Create Document Again
        </button>
        <b-spinner
          v-if="processing"
          class="ml-3"
          variant="primary"
          label="Please wait..."
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4";
</style>

<script>
export default {
  name: "Document",

  props: {
    documentName: String,
    document: Object,
    mailtoLink: String,
    helpText: String,
    onCreateDocument: Function,
    isReadOnly: Boolean,
    processing: Boolean
  }
};
</script>
