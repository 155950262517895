<template>
  <ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
    <div>
    <!-- begin: Client Business Details -->
    <ValidationObserver ref="clientBusinessDetails">
      <h3 class="font-weight-bold text-dark">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'clientBusinessDetailsPanel'">
          <img v-if="!isClientBusinessDetailsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand"/>
          <img v-if="isClientBusinessDetailsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse"/>
        </b-button>
        Client Business Details
      </h3>
      <b-collapse id="clientBusinessDetailsPanel" class="mb-5" v-model="isClientBusinessDetailsPanelExpanded">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
          v-if="appData && appData.client && appData.policy && appData.transaction"
        >
          Provided below are details of the client's business as specified in their Motor Proposal Form submission.
          These values can be updated below. All fields below are mandatory.<br />

          <div class="row mt-5 mb-0">
            <div class="col-6">
              <!-- Rentsure/CRI Business Activities -->
              <div v-if="!isBrandFuse">
                <table class="table table-sm table-bordered">
                  <thead>
                    <th width="60%" class="text-center">Business Activity</th>
                    <th width="40%" class="text-center">Percentage (%)</th>
                  </thead>
                  <tr v-for="(businessActivity, index) in appData.transaction.K_BusinessActivitiesJson" :key="index">
                    <td>
                      <ValidationProvider rules="required" :name="`businessActivity${index}.key`" v-slot="{ errors }">
                        <b-form-select class="custom-select custom-select-md" v-model="businessActivity.key" :disabled="isReadOnly">
                          <option v-for="ba in appData.allBusinessActivities" :value="ba.K_BusinessActivityKey" :key="ba.K_BusinessActivityKey">
                            {{ ba.Name }}
                          </option>
                        </b-form-select>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider rules="required|min_value:1|max_value:100" :name="`businessActivity${index}.percentage`" v-slot="{ errors }">
                        <input type="number" min="1" max="100" class="form-control" v-model="businessActivity.percentage"
                               @input="calculateBusinessActivityPercentTotal" :disabled="isReadOnly"/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <button class="btn btn-sm btn-outline-danger" @click.prevent="deleteBusinessActivity(index)" title="Delete" v-if="!isReadOnly">
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </table>
                <ValidationProvider :rules="{ 'required': { allowFalse: false }}" name="atleast1BusinessActivitySelected" v-slot="{ errors }">
                  <input type="number" hidden="hidden" class="form-control" v-model="atleast1BusinessActivitySelected"
                         :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <div class="form-group row mb-0">
                <span class="col-9 text-right">
                  <label class="col-form-label"><strong> Total (%) </strong></label>
                </span>
                  <span class="col-3 pt-3">
                  <strong>{{ appData.transaction.businessActivitiesPercentTotal }}</strong>
                  <ValidationProvider rules="required|totalPercentage" name="businessActivitiesPercentTotal" v-slot="{ errors }">
                    <input type="number" v-model="appData.transaction.businessActivitiesPercentTotal" hidden/>
                    <span class="text-danger"><br>{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
                </div>
                <div>
                  <button class="btn btn-primary" @click.prevent="addBusinessActivity()" v-if="!isReadOnly">
                    Add Business Activity
                  </button>
                </div>
              </div>
              <!-- End of Rentsure/CRI Business Activities -->

              <!-- Fuse Business Activities -->
              <div v-if="isBrandFuse" class="form-group">
                <label class="col-form-label"><strong>Business Activities</strong></label>
                <ValidationProvider rules="required|max:2000" name="baManual" v-slot="{ errors }">
                  <textarea type="text" maxlength="2000" class="form-control" v-model="appData.transaction.K_FuseBusinessActivities"
                         rows="4" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End of Fuse Business Activities -->
            </div>

            <div class="col-6">
              <table class="table table-sm table-bordered">
                <thead>
                  <th width="60%" class="text-center">Other Details</th>
                  <th width="40%" class="text-center"></th>
                </thead>
                <tr>
                  <td style="vertical-align: middle;">% Finance</td>
                  <td>
                    <ValidationProvider rules="required" name="K_PercentageOfFleetFinanced" v-slot="{ errors }">
                      <b-form-select class="form-control" v-model="appData.transaction.K_PercentageOfFleetFinanced" :disabled="isReadOnly">
                        <option></option>
                        <option value="None">None</option>
                        <option value="1-10%">1-10%</option>
                        <option value="11-20%">11-20%</option>
                        <option value="21-30%">21-30%</option>
                        <option value="31-40%">31-40%</option>
                        <option value="41-50%">41-50%</option>
                        <option value="51% or Over">51% or Over</option>
                      </b-form-select>
                      <span class="text-danger"><br>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr v-if="!isBrandFuse">
                  <td style="vertical-align: middle;">% Cash Rental</td>
                  <td>
                    <ValidationProvider rules="required" name="K_PercentageOfCashRentals" v-slot="{ errors }">
                      <b-form-select class="form-control" v-model="appData.client.K_PercentageOfCashRentals" :disabled="isReadOnly">
                        <option></option>
                        <option value="None">None</option>
                        <option value="1-5%">1-5%</option>
                        <option value="6-10%">6-10%</option>
                        <option value="11-15%">11-15%</option>
                        <option value="16-20%">16-20%</option>
                        <option value="21% or Over">21% or Over</option>
                      </b-form-select>
                      <span class="text-danger"><br>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle;">NSW Stamp Duty Exempt</td>
                  <td>
                    <ValidationProvider rules="required" name="K_NswStampDutyExempt" v-slot="{ errors }">
                      <b-form-radio-group v-model="appData.client.K_NswStampDutyExempt" class="form-control" :disabled="isReadOnly">
                        <b-form-radio value="Yes">Yes</b-form-radio>
                        <b-form-radio class="ml-2" value="No">No</b-form-radio>
                      </b-form-radio-group>
                    <span class="text-danger"><br>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
              </table>
            </div>

            <!-- Fuse Vehicle Use % -->
            <div v-if="isBrandFuse" class="form-group row col-12 mb-0">
              <table class="table table-sm table-bordered">
                <thead>
                <th width="80%" class="text-center">Vehicle Use</th>
                <th width="20%" class="text-center">Fleet Mix (%)</th>
                </thead>
                <tr>
                  <td class="text-center" style="vertical-align: middle">Low - Private use, typically makes 2 or less trips a day, less than 10,000 annual km travelled.</td>
                  <td class="text-center">
                    <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseLow" v-slot="{ errors }">
                      <input type="number" min="0" max="100" class="form-control" v-model="appData.transaction.K_VehicleUseJson.lowPercent" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" style="vertical-align: middle">Medium - On the road but less than half the day, 3-5 trips a day, 10,000 to 20,000 annual km travelled.</td>
                  <td class="text-center">
                    <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseMedium" v-slot="{ errors }">
                      <input type="number" min="0" max="100" class="form-control" v-model="appData.transaction.K_VehicleUseJson.mediumPercent" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" style="vertical-align: middle">High - On the road for most of the day, 5-10 trips a day, 20,000 to 30,000 annual km travelled.</td>
                  <td class="text-center">
                    <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseHigh" v-slot="{ errors }">
                      <input type="number" min="0" max="100" class="form-control" v-model="appData.transaction.K_VehicleUseJson.highPercent" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" style="vertical-align: middle">Extreme - On the road for the whole day, 10+ trips a day, more than 30,000 annual km travelled.</td>
                  <td class="text-center">
                    <ValidationProvider rules="required|min_value:0|max_value:100" name="vehicleUseExtreme" v-slot="{ errors }">
                      <input type="number" min="0" max="100" class="form-control" v-model="appData.transaction.K_VehicleUseJson.extremePercent" :disabled="isReadOnly"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="text-right" style="vertical-align: middle">Total</td>
                  <td class="text-center">
                    <label class="col-form-label ml-2"><strong>{{ totalVehicleUsePercent }} %</strong></label>
                    <ValidationProvider rules="required|totalPercentage" name="totalVehicleUsePercent" v-slot="{ errors }">
                      <input type="number" v-model="totalVehicleUsePercent" hidden/>
                      <span class="text-danger"><br>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
              </table>
            </div>
            <!-- End of Fuse Vehicle Use % -->
          </div>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Client Business Details -->

    <!-- begin: Current Policy Details -->
    <ValidationObserver ref="currentPolicyDetails">
      <h3 class="font-weight-bold text-dark mt-10" v-if="!isRenewalTransaction">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'currentPolicyDetailsPanel'">
          <img v-if="!isCurrentPolicyDetailsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isCurrentPolicyDetailsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Current Policy Details
      </h3>
      <b-collapse id="currentPolicyDetailsPanel" class="mb-5" v-model="isCurrentPolicyDetailsPanelExpanded">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
                v-if="appData && appData.transaction"
        >
          <strong>Provided below are details of the client's current policy as specified in their Motor Proposal Form submission.</strong>
          <div class="form-group row mb-0 mt-2">
            <label class="col-3 col-form-label text-right">Current Insurer</label>
            <div class="col-3 font-weight-bold col-form-label">
              {{ appData.transaction.K_CurrentInsurerName }}
            </div>

            <label class="col-3 col-form-label text-right">Current Policy Number</label>
            <div class="col-3 font-weight-bold col-form-label">
              {{ appData.transaction.K_CurrentPolicyNumber }}
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-3 col-form-label text-right">Current Broker Name</label>
            <div class="col-3 font-weight-bold col-form-label">
              {{ appData.transaction.K_CurrentBroker }}
            </div>

            <label class="col-3 col-form-label text-right">Current Expiry Date</label>
            <div class="col-3 font-weight-bold col-form-label" v-if="appData.transaction.K_CurrentExpiryDate">
              {{ appData.transaction.K_CurrentExpiryDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-3 col-form-label text-right">Special Excesses/Conditions</label>
            <div class="col-9 font-weight-bold col-form-label">
              {{ appData.transaction.K_SpecialExcessesConditions }}
            </div>
          </div>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Current Policy Details -->

    <!-- begin: Requirements of New Policy -->
    <ValidationObserver ref="requirementsOfPolicy">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'panel3'">
          <img v-if="!isPanel3Expanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isPanel3Expanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Requirements of New Policy
      </h3>
      <b-collapse v-model="isPanel3Expanded" id="panel3" class="mb-5">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
          v-if="appData && appData.transaction"
        >
          <strong>
            Provided below are details of the client's business as specified in
            their Motor Proposal Form submission. These values can be overridden
            by updating the values below.All fields below are mandatory.
          </strong><br/>

          <div class="form-group row mb-0 mt-5">
              <label class="col-3 col-form-label text-right">Insurance Start Date</label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_ProposedStartDate" v-slot="{ errors }">
                  <b-form-datepicker
                    class="form-control" v-model="appData.transaction.K_ProposedStartDate"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-AU"
                    :disabled="isReadOnly"
                  >
                  </b-form-datepicker>
                  <span class="text-danger"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

            <label class="col-3 col-form-label text-right">Premium Type</label>
            <div class="col-3 mb-1">
              <ValidationProvider rules="required" name="K_PaymentMethod" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="appData.transaction.K_PaymentMethod"
                               @input="paymentMethodChanged" :disabled="isReadOnly">
                  <option></option>
                  <option value="Monthly Premium">Monthly Premium</option>
                  <option value="Annual Premium">Annual Premium</option>
                  <option value="Burning Cost">Burning Cost</option>
                </b-form-select>
                <span class="text-danger"><br>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-3 col-form-label text-right">Insurance End Date</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_ProposedEndDate" v-slot="{ errors }">
                <b-form-datepicker
                  class="form-control" v-model="appData.transaction.K_ProposedEndDate"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}" locale="en-AU"
                  :disabled="isReadOnly"
                ></b-form-datepicker>
                <span class="text-danger"><br>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <label class="col-3 col-form-label text-right">Required Excess</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_RequiredExcess" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="appData.transaction.K_RequiredExcess"
                               :options="allowedExcesses" text-field="name" value-field="value" :disabled="isReadOnly"
                >
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Requirements of New Policy -->

    <!-- begin: Fleet & Claims History -->
    <ValidationObserver ref="fleetClaimsHistory">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'panel4'">
          <img v-if="!isPanel4Expanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isPanel4Expanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Fleet & Claims History
      </h3>
      <b-collapse v-model="isPanel4Expanded" id="panel4" class="mb-5">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
          v-if="appData && appData.transaction"
        >
          <strong>Provided below is a summary of the client's fleet and claims history as specified in their Motor Proposal Form submission.</strong>

          <h5 class="mt-5 text-center"><strong>Fleet History</strong></h5>
          <table class="table table-bordered mt-2">
            <thead>
              <th width="10%" class="text-center">Year</th>
              <th width="18%" class="text-center">Comprehensive</th>
              <th width="18%" class="text-center">TPPD</th>
              <th width="18%" class="text-center">Total Fleet Size</th>
              <th width="18%" class="text-center">Comprehensive Excess</th>
              <th width="18%" class="text-center">TPPD Excess</th>
            </thead>
            <tr class="text-center">
              <td style="vertical-align: middle;">Current Year</td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ComprehensiveCurrentYear"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TppdCurrentYear"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TotalFleetSizeCurrentYear"/></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_ComprehensiveExcessCurrentYear" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_TppdExcessCurrentYear" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">1 Year Prior</td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Comprehensive1YearAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Tppd1YearAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TotalFleetSize1YearAgo"/></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_ComprehensiveExcess1YearAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_TppdExcess1YearAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">2 Years Prior</td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Comprehensive2YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Tppd2YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TotalFleetSize2YearsAgo"/></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_ComprehensiveExcess2YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_TppdExcess2YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">3 Years Prior</td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Comprehensive3YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Tppd3YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TotalFleetSize3YearsAgo"/></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_ComprehensiveExcess3YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_TppdExcess3YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">4 Years Prior</td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Comprehensive4YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_Tppd4YearsAgo"/></td>
              <td><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_TotalFleetSize4YearsAgo"/></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_ComprehensiveExcess4YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
              <td><b-form-select class="form-control" v-model="appData.transaction.K_TppdExcess4YearsAgo" :options="allowedHistoricalExcesses" text-field="name" value-field="value" :disabled="isReadOnly"></b-form-select></td>
            </tr>
          </table>

          <h5 class="mt-7 text-center"><strong>Claims History</strong></h5>
          <table class="table table-bordered mt-2">
            <thead>
              <th width="9%" class="text-center">Year</th>
              <th width="10%" class="text-center">Working Losses Claims</th>
              <th width="15%" class="text-center">Net Incurred Working Losses</th>
              <th width="10%" class="text-center">Large Losses (>$20,000) Claims</th>
              <th width="15%" class="text-center">Net Incurred Large Losses</th>
              <th width="10%" class="text-center">Natural Peril Losses Claims</th>
              <th width="15%" class="text-center">Net Incurred Natural Peril Losses</th>
              <th width="15%" class="text-center">Total Net Incurred</th>
            </thead>
            <tr class="text-center">
              <td style="vertical-align: middle;">Current Year</td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountWorkingLossesCurrentYear"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredWorkingLossesCurrentYear" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountLargeLossesCurrentYear"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredLargeLossesCurrentYear" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountNaturalPerilLossesCurrentYear"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredNaturalPerilLossesCurrentYear" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;">{{ appData.transaction.niCurrentYear | currency }}</td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">1 Year Prior</td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountWorkingLosses1YearAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredWorkingLosses1YearAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountLargeLosses1YearAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredLargeLosses1YearAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountNaturalPerilLosses1YearAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredNaturalPerilLosses1YearAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;">{{ appData.transaction.ni1YearAgo | currency }}</td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">2 Years Prior</td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountWorkingLosses2YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredWorkingLosses2YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountLargeLosses2YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredLargeLosses2YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountNaturalPerilLosses2YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredNaturalPerilLosses2YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;">{{ appData.transaction.ni2YearsAgo | currency }}</td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">3 Years Prior</td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountWorkingLosses3YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredWorkingLosses3YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountLargeLosses3YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredLargeLosses3YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountNaturalPerilLosses3YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredNaturalPerilLosses3YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;">{{ appData.transaction.ni3YearsAgo | currency }}</td>
            </tr>
            <tr class="text-center">
              <td style="vertical-align: middle;">4 Years Prior</td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountWorkingLosses4YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredWorkingLosses4YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountLargeLosses4YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredLargeLosses4YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;"><input type="number" class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_ClaimsCountNaturalPerilLosses4YearsAgo"/></td>
              <td style="vertical-align: middle;"><currency-input class="form-control" :disabled="isReadOnly" v-model="appData.transaction.K_NetIncurredNaturalPerilLosses4YearsAgo" :precision="0" @input="calculateNetIncurredTotals"/></td>
              <td style="vertical-align: middle;">{{ appData.transaction.ni4YearsAgo | currency }}</td>
            </tr>
          </table>
          <button v-if="!isReadOnly && isRenewalTransaction" class="btn btn-primary" @click.prevent="$emit('refreshClaimsHistory')" >
            Refresh Claims History
          </button>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Fleet & Claims History -->

    <!-- begin: Summary of Vehicle Types -->
    <ValidationObserver ref="vehicleTypesAndPostcodes">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'panel5'">
          <img v-if="!isPanel5Expanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isPanel5Expanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Vehicle Types & Postcode Distribution
      </h3>
      <b-collapse id="panel5" v-model="isPanel5Expanded" class="mb-5">
        <b-card v-if="appData && appData.transaction"
                border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
        >
          <div v-if="!fleetLookupRunning">
            <strong>
              Click the button below to load the vehicle/postcode summary using the fleet declared on the previous page.
              Optionally, you can manually enter the data in the table below.
            </strong>
            <div class="form-group mt-2">
              <button class="btn btn-primary" @click.prevent="confirmFleetDataLoad" :disabled="isReadOnly">
                Load Data From Fleet Declaration
              </button>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-3 col-form-label font-size-lg font-size-h6"><strong>Select Vehicle Input Method</strong></label>
            <div class="col-6">
              <ValidationProvider rules="required" name="K_VehicleInputMethod" v-slot="{ errors }">
                <b-form-radio-group size="lg" v-model="appData.transaction.K_VehicleInputMethod" class="form-control" :disabled="isReadOnly">
                  <b-form-radio value="Postcode Distribution"><strong>Postcode Distribution</strong></b-form-radio>
                  <b-form-radio class="ml-2" value="Granular Postcode Information"><strong>Granular Postcode Information</strong></b-form-radio>
                </b-form-radio-group>
                <span class="text-danger"><br>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <table class="table table-sm table-bordered mt-3" v-if="appData && appData.transaction">
            <thead>
              <th width="25%" class="text-center">Vehicle Type</th>
              <th width="18%" class="text-center">No. of Vehicles</th>
              <th width="17%" class="text-center">Total Market Value</th>
              <th width="15%" class="text-center">Cover Type</th>
              <th v-if="isMethodGranularPostcode" width="10%" class="text-center">State</th>
              <th v-if="isMethodGranularPostcode" width="10%" class="text-center">Postcode</th>
              <th width="5%"></th>
            </thead>

            <tr v-for="(vehicle, index) in appData.transaction.K_VehiclesSummary" :key="index">
              <td>
                <ValidationProvider rules="required" :name="`K_VehicleType${index}`" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="vehicle.K_VehicleType" :options="vehicleTypes"
                                 text-field="Name" value-field="idObject" size="sm" :disabled="isReadOnly"
                  />
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="required" :name="`K_NumberOfVehicles${index}`" v-slot="{ errors }">
                  <input type="number" class="form-control" v-model="vehicle.K_NumberOfVehicles"
                         @input="calculateVehiclePremiumTotals" :disabled="isReadOnly"
                  />
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider :rules="{'required': vehicle.K_TypeOfCover !== 'Section 2 Only'}" :name="`K_TotalMarketValue${index}`" v-slot="{ errors }">
                  <currency-input
                    class="form-control" v-model="vehicle.K_TotalMarketValue"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="0"
                    @input="calculateVehiclePremiumTotals" :disabled="isReadOnly"
                  />
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="required" :name="`K_TypeOfCover${index}`" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="vehicle.K_TypeOfCover" size="sm" :disabled="isReadOnly">
                    <option value="Section 1 & 2">Section 1 & 2</option>
                    <option value="Section 2 Only">Section 2 Only</option>
                    <option v-if="isRenewalTransaction" value="Static Risk">Static Risk</option>
                  </b-form-select>
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td v-if="isMethodGranularPostcode">
                <ValidationProvider rules="required" :name="`K_State${index}`" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="vehicle.K_State" size="sm" :disabled="isReadOnly">
                    <option value="NSW">NSW</option>
                    <option value="VIC">VIC</option>
                    <option value="ACT">ACT</option>
                    <option value="QLD">QLD</option>
                    <option value="WA">WA</option>
                    <option value="SA">SA</option>
                    <option value="NT">NT</option>
                    <option value="TAS">TAS</option>
                    <option value="NZ">NZ</option>
                  </b-form-select>
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td v-if="isMethodGranularPostcode">
                <ValidationProvider rules="required|numeric" :name="`K_Postcode${index}`" v-slot="{ errors }">
                  <input type="text" class="form-control" v-model="vehicle.K_Postcode" maxlength="4" minlength="4"
                         :disabled="isReadOnly"/>
                  <span class="text-danger" v-if="errors.length > 0"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <button class="btn btn-sm btn-outline-danger" title="Delete" @click.prevent="deleteVehicleTypeRow(index)" :disabled="isReadOnly">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr>
              <button class="btn btn-primary m-2" @click.prevent="addVehicleTypeRow()" :disabled="isReadOnly">
                Add Row
              </button>
              <ValidationProvider :rules="{ 'required-action': { allowFalse: false }}" name="atleast1VehicleTypeAdded" v-slot="{ errors }">
                <input type="number" hidden="hidden" class="form-control" v-model="atleast1VehicleTypeAdded"
                       :disabled="isReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </tr>

            <tr>
              <td></td>
              <td style="vertical-align: middle;">
                <strong>Total:<br />{{ appData.transaction.K_TotalNumberOfVehicles }}</strong>
              </td>
              <td style="vertical-align: middle;">
                <strong v-if="appData.transaction.K_AverageTotalMarketValue">
                  Average:<br />{{ appData.transaction.K_AverageTotalMarketValue | currency }}
                </strong>
              </td>
              <td></td>
              <td></td>
            </tr>
          </table>

          <div v-if="!isMethodGranularPostcode">
            <br/>
            <h5>Postcode Distribution</h5>
            <div>Enter the postcode distribution for the above vehicles. <b>For New Zealand, enter 'NZ' as the postcode.</b></div>

            <table class="table table-sm table-bordered mt-3 col-6" v-if="appData && appData.transaction">
              <thead>
              <th width="50%" class="text-center">Postcode</th>
              <th width="50%" class="text-center">Percentage of Vehicles</th>
              </thead>

              <tr v-for="(pCode, index) in appData.transaction.K_PostcodeDistributionJson" :key="index">
                <td>
                  <ValidationProvider rules="required" :name="`pcode${index}.postcode`" v-slot="{ errors }">
                    <input type="text" class="form-control" v-model="pCode.postcode" maxlength="4"
                           @input="calculatePostcodeDistributionTotal" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider rules="required|min_value:0.1|max_value:100" :name="`pcode${index}.percentage`" v-slot="{ errors }">
                    <input type="number" min="1" max="100" class="form-control" v-model="pCode.percentage"
                           @input="calculatePostcodeDistributionTotal" :disabled="isReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </td>
                <td>
                  <button class="btn btn-sm btn-outline-danger" @click.prevent="deletePostcodeEntry(index)" title="Delete" v-if="!isReadOnly">
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <button class="btn btn-primary m-2" :disabled="isReadOnly" @click.prevent="addPostcodeEntry">
                  Add Row
                </button>
                <ValidationProvider :rules="{ 'required-action': { allowFalse: false }}" name="atleast1PostcodeAdded" v-slot="{ errors }">
                  <input type="number" hidden="hidden" class="form-control" v-model="atleast1PostcodeAdded"
                         :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </tr>
              <tr>
                <td></td>
                <span class="col-9 text-right">
                  <label class="col-form-label"><strong> Total (%) </strong></label>
                </span>
                <span class="col-3 pt-3">
                  <strong>{{ appData.transaction.pdTotal }}</strong>
                  <ValidationProvider rules="required|totalPercentage" name="pdTotal" v-slot="{ errors }">
                    <input type="number" v-model="appData.transaction.pdTotal" hidden/>
                    <span class="text-danger"><br>{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </tr>
            </table>
          </div>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Summary of Vehicle Types and Premium Details -->

    <!-- begin: Rating Questions -->
    <ValidationObserver ref="ratingQuestions">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'ratingQuestionsPanel'">
          <img v-if="!isRatingQuestionsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isRatingQuestionsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Rating Questions
      </h3>
      <b-collapse v-model="isRatingQuestionsPanelExpanded" id="ratingQuestionsPanel" class="mb-5">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
                v-if="appData && appData.transaction"
        >
          <strong>
            Please provide answers to the rating questions below.
          </strong><br/>

          <div class="form-group row mb-1 mt-5">
            <label class="col-3 col-form-label text-right">Claims as at Date</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_ClaimsAsAtDate" v-slot="{ errors }">
                <b-form-datepicker class="form-control" v-model="appData.transaction.K_ClaimsAsAtDate"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}" locale="en-AU"
                    :disabled="isReadOnly"
                ></b-form-datepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <label class="col-3 col-form-label text-right">Avg Reporting Delay</label>
            <div class="col-3 ">
              <ValidationProvider rules="required" name="K_AverageReportingDelay" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="appData.transaction.K_AverageReportingDelay" :disabled="isReadOnly">
                  <option></option>
                  <option value="<1">Less than 1</option>
                  <option value="1-10">1-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21-30">21-30</option>
                  <option value="31-40">31-40</option>
                  <option value="41-60">41-60</option>
                  <option value="61-80">61-80</option>
                  <option value="81-100">81-100</option>
                  <option value="101-120">101-120</option>
                  <option value="121-140">121-140</option>
                  <option value="141-160">141-160</option>
                  <option value="161-180">161-180</option>
                  <option value="181-200">181-200</option>
                  <option value="200+">200+</option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mb-1">
            <label class="col-3 col-form-label text-right">Total Commission %</label>
            <div class="col-3">
              <ValidationProvider rules="required|min_value:0|max_value:100" name="K_TotalCommissionPercent" v-slot="{ errors }">
                <input type="number" class="form-control" min="0" max="100"
                       v-model="appData.transaction.K_TotalCommissionPercent" :disabled="isReadOnly">
                <span class="text-danger">{{ errors[0] }}</span>
                <span class="text-warning" v-if="showZeroCommissionWarning">Warning - Zero commission % entered</span>
              </ValidationProvider>
            </div>

            <label class="col-3 col-form-label text-right">Broker Commission %</label>
            <div class="col-3">
              <ValidationProvider rules="required|min_value:0|max_value:100" name="K_BrokerCommissionPercent" v-slot="{ errors }">
                <input type="number" class="form-control" min="0" max="100"
                       v-model="appData.transaction.K_BrokerCommissionPercent" :disabled="isReadOnly">
                <span class="text-danger">{{ errors[0] }}</span>
                <span class="text-danger" v-if="showBrokerCommissionError">Broker Commission % cannot be less than Total Commission %.</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mb-1">
            <label class="col-3 col-form-label text-right">Other Vehicle Basis</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_OtherBasis" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="appData.transaction.K_OtherBasis"
                               :options="vehicleTypes" text-field="Name" value-field="Name" :disabled="isReadOnly"
                >
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Rating Questions -->

    <!-- begin: Rating Output -->
    <ValidationObserver ref="ratingOutput">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'ratingOutputPanel'">
          <img v-if="!isRatingOutputPanelExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isRatingOutputPanelExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Rating Output & Premiums
      </h3>
      <b-collapse v-model="isRatingOutputPanelExpanded" id="ratingOutputPanel" class="mb-5">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
                v-if="appData && appData.transaction"
        >

          <div class="d-flex justify-content-center mb-10" v-if="!isReadOnly">
            <button v-if="!uwPremiumOverride" class="btn btn-lg btn-primary" title="Calculate Premiums" @click.prevent="calculatePremiums">
              Calculate Premiums
            </button>
            <button v-if="!uwPremiumOverride" class="btn btn-lg btn-warning ml-5" title="Override Premiums" @click.prevent="confirmUwOverride">
              Override Premiums
            </button>
            <button v-if="uwPremiumOverride" class="btn btn-lg btn-outline-warning" title="Cancel Premium Override" @click.prevent="confirmUwOverrideCancel">
              Cancel Premium Override
            </button>
            <button class="btn btn-lg btn-outline-primary ml-5" title="Save Page PDF" @click.prevent="savePremiumPage">
              Save Page PDF
            </button>
          </div>

          <div class="font-weight-bold text-dark font-size-lg mb-2">
            Vehicles and Premiums
          </div>
          <table class="table table-sm table-bordered mt-3" v-if="appData && appData.transaction">
            <thead>
            <th width="20%" class="text-center">Vehicle Type</th>
            <th width="10%" class="text-center">No. of Vehicles</th>
            <th width="15%" class="text-center">Total Market Value</th>
            <th width="15%" class="text-center">Cover Type</th>
            <th width="15%" class="text-center">Base Premium Per Vehicle</th>
            <th width="15%" class="text-center" v-if="uwPremiumOverride">Base Premium Per Vehicle - UW Override</th>
            <th width="10%" class="text-center">Rate Per Vehicle %</th>
            </thead>

            <tr v-for="(vehicle, index) in appData.transaction.K_VehiclesSummary" :key="index">
              <td style="vertical-align: middle;">
                <b-form-select class="form-control" v-model="vehicle.K_VehicleType"
                    :options="vehicleTypes" text-field="Name" value-field="idObject" size="sm" disabled
                >
                </b-form-select>
              </td>
              <td>
                <input type="number" class="form-control" v-model="vehicle.K_NumberOfVehicles" disabled/>
              </td>
              <td>
                <currency-input class="form-control" v-model="vehicle.K_TotalMarketValue"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="0"
                                disabled
                />
              </td>
              <td style="vertical-align: middle;">
                <b-form-select class="form-control" v-model="vehicle.K_TypeOfCover" size="sm" disabled>
                  <option value="Section 1 & 2">Section 1 & 2</option>
                  <option value="Section 2 Only">Section 2 Only</option>
                  <option v-if="isRenewalTransaction" value="Static Risk">Static Risk</option>
                </b-form-select>
              </td>
              <td>
                <ValidationProvider rules="required" :name="`K_BasePremiumPerVehicle${index}`" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="vehicle.K_BasePremiumPerVehicle"
                                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                                  @input="calculateVehiclePremiumTotals" disabled
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td v-if="uwPremiumOverride">
                <ValidationProvider rules="required" :name="`K_BasePremiumPerVehicleUwOverride${index}`" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="vehicle.K_BasePremiumPerVehicleUwOverride"
                                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                                  :disabled="isReadOnly"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="required" :name="`K_RatePerVehicle${index}`" v-slot="{ errors }">
                  <input type="number" min="0" max="100" class="form-control" v-model="vehicle.K_RatePerVehicle"
                         @input="calculateVehiclePremiumTotals" disabled
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="vertical-align: middle;">
                <strong>Total: <br />{{ appData.transaction.K_TotalNumberOfVehicles }}</strong>
              </td>
              <td style="vertical-align: middle;">
                <strong v-if="appData.transaction.K_AverageTotalMarketValue">
                  Average:<br />{{ appData.transaction.K_AverageTotalMarketValue | currency }}
                </strong>
              </td>
              <td></td>
              <td style="vertical-align: middle;">
                <strong v-if="appData.transaction.K_AverageBasePremiumPerVehicle">
                  Average:<br />{{ appData.transaction.K_AverageBasePremiumPerVehicle | currency }}
                </strong>
              </td>
              <td v-if="uwPremiumOverride" style="vertical-align: middle;">
                <strong v-if="appData.transaction.K_AverageBasePremiumPerVehicleUwOverride">
                  Average:<br />{{ appData.transaction.K_AverageBasePremiumPerVehicleUwOverride | currency }}
                </strong>
              </td>
              <td style="vertical-align: middle;">
                <strong v-if="appData.transaction.K_AverageRatePerVehicle">
                  Average:<br />{{ appData.transaction.K_AverageRatePerVehicle }} %</strong>
              </td>
            </tr>
          </table>

          <div class="font-weight-bold text-dark font-size-lg mb-2 mt-2">
            State Charges
          </div>
          <table class="table table-sm table-bordered mt-2" v-if="appData && appData.transaction && appData.transaction.K_StateSummary">
            <thead>
            <th width="20%" class="text-center">State</th>
            <th width="20%" class="text-center">ESL/FSL</th>
            <th width="20%" class="text-center">Stamp Duty</th>
            <th width="20%" class="text-center">GST</th>
            <th width="20%" class="text-center">Annual Premium</th>
            </thead>

            <tr v-for="(state, index) in appData.transaction.K_StateSummary" :key="index">
              <td class="text-center" style="vertical-align: middle;">
                {{ state.K_State }}
              </td>
              <td>
                <currency-input
                    class="form-control" v-model="state.K_EslFsl"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateStateSummaryTotals" :disabled="isReadOnly || !uwPremiumOverride"
                />
              </td>
              <td>
                <currency-input class="form-control" v-model="state.K_StampDuty"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateStateSummaryTotals" :disabled="isReadOnly || !uwPremiumOverride"
                />
              </td>
              <td>
                <currency-input class="form-control" v-model="state.K_Gst"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateStateSummaryTotals" :disabled="isReadOnly || !uwPremiumOverride"
                />
              </td>
              <td>
                <currency-input class="form-control" v-model="state.K_AnnualPremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateStateSummaryTotals" :disabled="isReadOnly || !uwPremiumOverride"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center" style="vertical-align: middle;"><strong>Total</strong></td>
              <td><strong> {{ appData.transaction.stateSummaryTotals.totalEslFsl | currency }} </strong></td>
              <td><strong>{{ appData.transaction.stateSummaryTotals.totalStampDuty | currency }}</strong></td>
              <td><strong> {{ appData.transaction.stateSummaryTotals.totalGst | currency }} </strong></td>
              <td><strong>{{ appData.transaction.stateSummaryTotals.totalAnnualPremium | currency }}</strong></td>
            </tr>
          </table>

          <div v-if="appData && experienceRating">
            <div class="font-weight-bold text-dark font-size-lg mb-2 mt-2">
              Experience Rating - Claims Summary
            </div>
            <table class="table table-sm table-bordered mt-2">
              <tr>
                <td colspan="2"></td>
                <td colspan="4" class="text-center font-weight-bold" style="background-color: lightblue;"><strong>Working Losses</strong></td>
                <td colspan="4" class="text-center font-weight-bold" style="background-color: lightcyan;"><strong>Large Losses</strong></td>
              </tr>
              <tr>
                <th width="10%" class="text-center" style="background-color: lightgray;">Year</th>
                <th width="10%" class="text-center" style="background-color: lightgray;">Fleet Size (Exposure)</th>
                <th width="10%" class="text-center" style="background-color: lightblue;">Claim Number</th>
                <th width="10%" class="text-center" style="background-color: lightblue;">Claim Frequency</th>
                <th width="10%" class="text-center" style="background-color: lightblue;">Average Size</th>
                <th width="10%" class="text-center" style="background-color: lightblue;">Cost Per Risk</th>
                <th width="10%" class="text-center" style="background-color: lightcyan;">Claim Number</th>
                <th width="10%" class="text-center" style="background-color: lightcyan;">Claim Frequency</th>
                <th width="10%" class="text-center" style="background-color: lightcyan;">Average Size</th>
                <th width="10%" class="text-center" style="background-color: lightcyan;">Cost Per Risk</th>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;">Current Year</td>
                <td style="vertical-align: middle;background-color: lightgray;">{{experienceRating.fleetSizeExposureCY}}</td>

                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCountCY}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsFreqCY}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsAcsCY | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCprCY | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCountCY}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsFreqCY}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;">${{experienceRating.largeClaimsAcsCY}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">${{experienceRating.largeClaimsCprCY}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;">1 Year Ago</td>
                <td style="vertical-align: middle;background-color: lightgray;">{{experienceRating.fleetSizeExposure1Y}}</td>

                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCount1Y}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsFreq1Y}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsAcs1Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCpr1Y | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCount1Y}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsFreq1Y}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsAcs1Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCpr1Y | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;">2 Years Ago</td>
                <td style="vertical-align: middle;background-color: lightgray;">{{experienceRating.fleetSizeExposure2Y}}</td>

                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCount2Y}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsFreq2Y}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsAcs2Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCpr2Y | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCount2Y}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsFreq2Y}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsAcs2Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCpr2Y | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;">3 Years Ago</td>
                <td style="vertical-align: middle;background-color: lightgray;">{{experienceRating.fleetSizeExposure3Y}}</td>

                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCount3Y}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsFreq3Y}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsAcs3Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCpr3Y | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCount3Y}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsFreq3Y}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsAcs3Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCpr3Y | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;">4 Years Ago</td>
                <td style="vertical-align: middle;background-color: lightgray;">{{experienceRating.fleetSizeExposure4Y}}</td>

                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCount4Y}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsFreq4Y}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsAcs4Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;">{{experienceRating.workingClaimsCpr4Y | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCount4Y}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsFreq4Y}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsAcs4Y | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;">{{experienceRating.largeClaimsCpr4Y | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;" class="font-weight-boldest">Avg. Weighted</td>
                <td style="vertical-align: middle;background-color: lightgray;" class="font-weight-boldest">{{experienceRating.fleetSizeExposureTotal}}</td>

                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsCountTotal}}</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsFreqAW}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsAcsAW | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsCprAW | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsCountTotal}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsFreqAW}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsAcsAW | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsCprAW | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;" class="font-weight-boldest">Year Weighted</td>
                <td style="vertical-align: middle;background-color: lightgray;" class="font-weight-boldest"></td>

                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest"></td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsFreqYW}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsAcsYW | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsCprYW | currency}}</td>

                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest"></td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsFreqYW}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsAcsYW | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsCprYW | currency}}</td>
              </tr>

              <tr class="text-center">
                <td style="vertical-align: middle;background-color: lightgray;" class="font-weight-boldest">Adopted</td>
                <td colspan="2"></td>

                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsFreqAdopted}}%</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsAcsAdopted | currency}}</td>
                <td style="vertical-align: middle;background-color: lightblue;" class="font-weight-boldest">{{experienceRating.workingClaimsCprAdopted | currency}}</td>

                <td></td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsFreqAdopted}}%</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsAcsAdopted | currency}}</td>
                <td style="vertical-align: middle;background-color: lightcyan;" class="font-weight-boldest">{{experienceRating.largeClaimsCprAdopted | currency}}</td>
              </tr>
            </table>
          </div>

          <div class="font-weight-bold text-dark font-size-lg mb-2">
            Premiums
          </div>
          <table class="table table-sm table-bordered mt-2" v-if="appData && appData.transaction">
            <thead>
              <th width="50%" class="text-center"></th>
              <th width="25%" class="text-center" style=" background-color: lightgray;">Annual Premium</th>
              <th width="25%" class="text-center" style=" background-color: lightgray;">Annual Premium (Per Risk)</th>
            </thead>
            <tr>
              <td class="text-center" style="vertical-align: middle; background-color: lightgray;">
                Experience Technical Premium (Annual)
              </td>
              <td>
                <ValidationProvider rules="" name="K_ExperienceTechnicalPremium" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_ExperienceTechnicalPremium"
                                  @input="calculateLossRatioPremium" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="" name="K_ExperienceTechnicalPremiumPerRisk" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_ExperienceTechnicalPremiumPerRisk" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="text-center" style="vertical-align: middle; background-color: lightgray;">
                Exposure Technical Premium (Annual)
              </td>
              <td>
                <ValidationProvider rules="" name="K_ExposureTechnicalPremium" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_ExposureTechnicalPremium" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="" name="K_ExposureTechnicalPremiumPerRisk" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_ExposureTechnicalPremiumPerRisk" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="text-center font-weight-bolder" style="vertical-align: middle;">
                Weighting (Experience) - UW Override %
              </td>
              <td>
                <ValidationProvider rules="" name="K_ExperienceWeightOverridePercent" v-slot="{ errors }">
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="appData.transaction.K_ExperienceWeightOverridePercent"
                           :disabled="isReadOnly">
                    <div class="input-group-append"><span class="input-group-text">%</span></div>
                  </div>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <span class="font-weight-bolder">UW Override Comments:</span>
                <ValidationProvider rules="max:2000" name="K_UwOverrideComments" v-slot="{ errors }">
                  <textarea class="form-control" v-model="appData.transaction.K_UwOverrideComments"
                          maxlength="2000" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="text-center" style="vertical-align: middle; background-color: lightgray;">
                Weighted Technical Premium (Annual)
              </td>
              <td>
                <ValidationProvider rules="" name="K_WeightedTechnicalPremium" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_WeightedTechnicalPremium" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="" name="K_WeightedTechnicalPremiumPerRisk" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_WeightedTechnicalPremiumPerRisk" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="text-center" style="vertical-align: middle; background-color: lightgray;">
                Walkaway Premium
              </td>
              <td>
                <ValidationProvider rules="" name="K_WalkawayPremium" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_WalkawayPremium" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider rules="" name="K_WalkawayPremiumPerRisk" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.transaction.K_WalkawayPremiumPerRisk" disabled/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="text-center font-weight-bolder" style="vertical-align: middle;">
                Market Adjustment %
              </td>
              <td>
                <ValidationProvider rules="" name="K_MarketAdjustmentPercent" v-slot="{ errors }">
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="appData.transaction.K_MarketAdjustmentPercent" :disabled="isReadOnly">
                    <div class="input-group-append"><span class="input-group-text">%</span></div>
                  </div>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
              <td>
                <span class="font-weight-bolder">Market Adjustment Comments:</span>
                <ValidationProvider rules="max:2000" name="K_MarketAdjustmentComments" v-slot="{ errors }">
                  <textarea class="form-control" v-model="appData.transaction.K_MarketAdjustmentComments"
                            maxlength="2000" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </table>

          <!-- begin: Other Fees Section -->
          <div class="font-weight-bold text-dark font-size-lg mb-2 mt-5">
            Other Fees
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Policy Establishment Fee</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_PolicyEstablishmentFee" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_PolicyEstablishmentFee"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateTotalFees" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-3 col-form-label text-right">Cancellation Fee</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_CancellationFee" v-slot="{ errors }">
                <currency-input class="form-control" v-model="appData.transaction.K_CancellationFee"
                                :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                                :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Administration Fee</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_AdministrationFees" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_AdministrationFees"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculateTotalFees" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-3 col-form-label text-right" v-if="appData.transaction.K_PaymentMethod === 'Annual Premium'">
              Endorsement Fee
            </label>
            <div class="col-3" v-if="appData.transaction.K_PaymentMethod === 'Annual Premium'">
              <ValidationProvider rules="required" name="K_EndorsementFee" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_EndorsementFee"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right" v-if="appData.transaction.K_PaymentMethod === 'Monthly Premium'">
              Monthly Fee
            </label>
            <div class="col-3" v-if="appData.transaction.K_PaymentMethod === 'Monthly Premium'">
              <ValidationProvider rules="required" name="K_MonthlyFee" v-slot="{ errors }">
                <currency-input class="form-control" v-model="appData.transaction.K_MonthlyFee"
                                :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                                @input="calculateTotalFees" :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <!-- end: Other Fees Section -->

          <!-- end: Fuse Analytics Fee Section -->
          <div v-if="appData.transaction.K_Brand === 'Fuse'" >
            <div class="font-weight-bold text-dark font-size-lg mb-2">
              Fuse Analytics Fee
            </div>
            <div class="form-group row mb-2">
              <label class="col-3 col-form-label text-right">Number of Applicable Vehicles</label>
              <div class="col-3">
                <ValidationProvider rules="required|min_value:0" name="K_NumberOfApplicableVehicles" v-slot="{ errors }">
                  <input class="form-control" type="number" min="0" v-model="appData.transaction.K_NumberOfApplicableVehicles"
                         @input="calculateTotalFees" :disabled="isReadOnly"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-3 col-form-label text-right">
                Fee Per Vehicle Per Annum
              </label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_FeePVPA" v-slot="{ errors }">
                  <currency-input
                      class="form-control" v-model="appData.transaction.K_FeePVPA"
                      :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                      @input="calculateTotalFees" :disabled="isReadOnly"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label class="col-3 col-form-label text-right">
                Total Analytics Fee
              </label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_TotalAnalyticsFee" v-slot="{ errors }">
                  <currency-input
                      class="form-control" v-model="appData.transaction.K_TotalAnalyticsFee"
                      :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                      disabled
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <!-- end: Fuse Analytics Fee Section -->

          <div class="d-flex justify-content-center mt-5 mb-5" v-if="!isReadOnly">
            <button v-if="!uwPremiumOverride" class="btn btn-lg btn-primary" title="Calculate Premiums" @click.prevent="calculatePremiums">
              Calculate Premiums
            </button>
            <button v-if="!uwPremiumOverride" class="btn btn-lg btn-warning ml-5" title="Override Premiums" @click.prevent="confirmUwOverride">
              Override Premiums
            </button>
            <button v-if="uwPremiumOverride" class="btn btn-lg btn-outline-warning" title="Cancel Premium Override" @click.prevent="confirmUwOverrideCancel">
              Cancel Premium Override
            </button>
            <button class="btn btn-lg btn-outline-primary ml-5" title="Save page PDF" @click.prevent="savePremiumPage">
              Save Page PDF
            </button>
          </div>

          <!-- begin: Estimated Annual Premium Section -->
          <div class="mt-10 mb-2 font-weight-bold text-dark font-size-lg">
            Estimated Annual Premium <br /> Based on the information disclosed at the time of quoting
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Base Premium - 80% Loss Ratio</label>
            <div class="col-3">
              <currency-input
                  class="form-control" v-model="appData.transaction.K_BasePremiumLossRatio"
                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                  :disabled="true"
              />
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Base Premium</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_BasePremium" v-slot="{ errors }">
                <currency-input
                  class="form-control" v-model="appData.transaction.K_BasePremium"
                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                  @input="calculatePremiumTotalsUwOverride" :disabled="isReadOnly || !uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-3 col-form-label text-right">Base Premium Per Risk</label>
            <div class="col-3">
              <ValidationProvider rules="" name="K_BasePremiumPerRisk" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_BasePremiumPerRisk"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="!uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">ESL / FSL</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_EslFsl" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_EslFsl"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculatePremiumTotalsUwOverride" :disabled="isReadOnly || !uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">UW GST</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_UwGst" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_UwGst"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculatePremiumTotalsUwOverride"  :disabled="isReadOnly || !uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Stamp Duty</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_StampDuty" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_StampDuty"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculatePremiumTotalsUwOverride" :disabled="isReadOnly || !uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">
              <b-badge variant="primary"><strong>Sub-Total</strong></b-badge>
            </label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_SubTotal" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_SubTotal"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Total Fees</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_TotalFees" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_TotalFees"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-3 col-form-label text-right">Total Fee GST</label>
            <div class="col-3">
              <ValidationProvider rules="required" name="K_TotalFeeGst" v-slot="{ errors }">
                <currency-input
                  class="form-control" v-model="appData.transaction.K_TotalFeeGst"
                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                  disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right"><b-badge variant="primary"><strong>Total Annual Premium</strong></b-badge></label>
            <div class="col-3">
              <ValidationProvider rules="" name="K_TotalAnnualPremium" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_TotalAnnualPremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-3 col-form-label text-right">Broker Commission</label>
            <div class="col-3">
              <ValidationProvider rules="" name="K_BrokerCommission" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_BrokerCommission"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    @input="calculatePremiumTotalsUwOverride" :disabled="isReadOnly || !uwPremiumOverride"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-3 col-form-label text-right">Broker Commission GST</label>
            <div class="col-3">
              <ValidationProvider rules="" name="K_BrokerCommissionGst" v-slot="{ errors }">
                <currency-input
                  class="form-control" v-model="appData.transaction.K_BrokerCommissionGst"
                  :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                  disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row mb-1">
            <label class="col-3 col-form-label text-right"><b-badge variant="primary"><strong>Net Payable to {{ this.appData.transaction.K_Brand }}</strong></b-badge></label>
            <div class="col-3">
              <ValidationProvider rules="" name="K_NetPayableToRentsure" v-slot="{ errors }">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_NetPayableToRentsure"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

          </div>
          <!-- end: Estimated Annual Premium Section -->

          <!-- begin: Burning Cost Details Section -->
          <div v-if="appData.transaction.K_PaymentMethod === 'Burning Cost'">
            <div class="mt-10 mb-2 font-weight-bold text-dark font-size-lg">
              Burning Cost Details
            </div>
            <div class="form-group row mb-1">
              <label class="col-3 col-form-label text-right">Deposit Premium</label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_DepositPremium" v-slot="{ errors }">
                  <currency-input
                    class="form-control" v-model="appData.transaction.K_DepositPremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="isReadOnly"
                  />
                  <span class="text-danger"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-3 col-form-label text-right">Minimum Premium</label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_MinimumPremium" v-slot="{ errors }">
                  <currency-input
                    class="form-control" v-model="appData.transaction.K_MinimumPremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="isReadOnly"
                  />
                  <span class="text-danger"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-3 col-form-label text-right">Maximum Premium</label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_MaximumPremium" v-slot="{ errors }">
                  <currency-input
                    class="form-control" v-model="appData.transaction.K_MaximumPremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="isReadOnly"
                  />
                  <span class="text-danger"><br>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <!-- end: Burning Cost Details Section -->

          <!-- begin: Other Details Section -->
          <div>
            <div class="font-weight-bold text-dark font-size-lg">
              Other Details
            </div>
            <div class="form-group row mb-2">
              <label class="col-3 col-form-label text-right">Credit Terms (Days)</label>
              <div class="col-3">
                <b-form-select class="form-control" v-model="appData.transaction.K_CreditTermsDays" :disabled="isReadOnly">
                  <option></option>
                  <option value="Seven (7)">Seven (7)</option>
                  <option value="Fourteen (14)">Fourteen (14)</option>
                  <option value="Twenty-one (21)">Twenty-one (21)</option>
                  <option value="Thirty (30)">Thirty (30)</option>
                  <option value="Fourty-five (45)">Fourty-five (45)</option>
                  <option value="Sixty (60)">Sixty (60)</option>
                  <option value="Ninety (90)">Ninety (90)</option>
                </b-form-select>
              </div>
              <label v-if="appData.transaction.K_PaymentMethod !== 'Burning Cost'" class="col-3 col-form-label text-right">Minimum Premium</label>
              <div v-if="appData.transaction.K_PaymentMethod !== 'Burning Cost'" class="col-3">
                <currency-input
                    class="form-control" v-model="appData.transaction.K_MinimumNonRefundablePremium"
                    :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree" :precision="currencyConfig.precision"
                    :disabled="isReadOnly"
                />
              </div>
            </div>
          </div>
          <!-- end: Other Details Section -->
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: Rating Output -->

    <!-- begin: UW Approvals -->
    <ValidationObserver ref="approvals">
      <h3 class="font-weight-bold text-dark mt-10">
        <b-button class="btn btn-icon btn-sm ml-1" v-b-toggle="'approvalsPanel'">
          <img v-if="!isApprovalsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-down.svg" title="Expand" alt="Expand"/>
          <img v-if="isApprovalsPanelExpanded" src="media/svg/icons/Navigation/Angle-double-up.svg" title="Collapse" alt="Collapse"/>
        </b-button>
        Underwriting Approvals
      </h3>
      <b-collapse v-model="isApprovalsPanelExpanded" id="approvalsPanel" class="mb-5">
        <b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" header-class="p-3 font-size-lg font-weight-bold"
                v-if="appData"
        >

          <div v-if="!appData.uwApprovalsDataValid">
            <span class="text-danger">Invalid approvals data - Please click on 'Refresh Approvals' button to get the latest data.</span>
          </div>

          <div class="d-flex justify-content-center mb-10" v-if="!isReadOnly">
            <button class="btn btn-lg btn-primary" title="Refresh Approvals" @click.prevent="refreshUwApprovals">
              Refresh Approvals
            </button>
          </div>

          <div v-if="!Array.isArray(appData.uwApprovals) || appData.uwApprovals.length === 0">
            No approvals pending.
          </div>

          <table class="table table-sm table-bordered mt-3" v-if="Array.isArray(appData.uwApprovals) && appData.uwApprovals.length > 0">
            <thead>
            <th width="30%" class="text-center">Approval Message</th>
            <th width="10%" class="text-center">Eligible Approvers</th>
            <th width="10%" class="text-center">Approval Status</th>
            <th width="11%" class="text-center">Actioned By</th>
            <th width="12%" class="text-center">Action Time</th>
            <th width="12%" class="text-center">Actions / Comments</th>
            </thead>

            <tr v-for="(approval, index) in appData.uwApprovals" :key="index">
              <td style="vertical-align: middle;">
                {{ approval.K_ApprovalMessage }}
              </td>
              <td style="vertical-align: middle;">
                {{ approval.K_Approvers.join(' / ') }}
              </td>
              <td class="text-center">
                <b-badge :variant="getApprovalStatusVariant(approval.K_ApprovalStatus)" class="mt-2">{{approval.K_ApprovalStatus}}</b-badge>
              </td>
              <td style="vertical-align: middle;">
                {{ approval.K_ActionedBy }}
              </td>
              <td style="vertical-align: middle;">
                <span v-if="approval.K_ActionTime">{{ approval.K_ActionTime | dateParse() | dateFormat("DD/MM/YYYY h:mm A") }}</span>
              </td>
              <td>
                <span v-if="!isReadOnly && approval.K_ApprovalStatus === 'Awaiting Approval' && isUserEligibleForApproval(approval)">
                  <button class="btn btn-sm btn-success" title="Approve" @click.prevent="confirmApprove(approval)">Approve</button>
                  <button class="btn btn-sm btn-danger ml-2" title="Reject" @click.prevent="confirmReject(approval)">Reject</button>
                </span>
                <span v-if="approval.K_ApprovalStatus !== 'Awaiting Approval'">
                  {{ approval.K_Comments }}
                </span>
              </td>
            </tr>
          </table>
          <b-modal ref="approveModal" title="Confirm Approval" size="md" scrollable no-close-on-backdrop hide-header-close
                   @ok="approveApproval" ok-title="Approve" ok-variant="success"
          >
            <div v-if="approvalBeingActioned">
              <b-card-text class="font-weight-bold font-size-h5">{{ approvalBeingActioned.K_ApprovalMessage }}</b-card-text>
              <div class="mt-5">
                <b-card-text>Comments (Optional)</b-card-text>
                <div class="form-group">
                  <b-form-textarea class="form-control" v-model="approvalBeingActioned.K_Comments" rows="3"></b-form-textarea>
                </div>
              </div>
              <div class="mt-4">
                <b-card-text>Attachments (Optional)</b-card-text>
                <div class="form-group">
                  <approval-attachments :approval="approvalBeingActioned" :is-read-only="isReadOnly"/>
                </div>
              </div>
              <b-card-text v-if="rejectionApprovalModalError" class="text-danger mt-3">{{ rejectionApprovalModalError }}</b-card-text>
            </div>
          </b-modal>

          <b-modal ref="rejectModal" title="Confirm Rejection" size="md" scrollable no-close-on-backdrop hide-header-close
              @ok="rejectApproval" ok-title="Reject" ok-variant="danger"
          >
            <div v-if="approvalBeingActioned">
              <b-card-text class="font-weight-bold font-size-h5">{{ approvalBeingActioned.K_ApprovalMessage }}</b-card-text>
              <div class="mt-5">
                <b-card-text>Comments</b-card-text>
                <div class="form-group">
                  <b-form-textarea class="form-control" v-model="approvalBeingActioned.K_Comments" rows="3" size="2000"></b-form-textarea>
                </div>
              </div>
              <div class="mt-4">
                <b-card-text>Attachments (Optional)</b-card-text>
                <div class="form-group">
                  <approval-attachments :approval="approvalBeingActioned" :is-read-only="isReadOnly"/>
                </div>
              </div>
              <b-card-text v-if="rejectionApprovalModalError" class="text-danger mt-3">{{ rejectionApprovalModalError }}</b-card-text>
            </div>
          </b-modal>
        </b-card>
      </b-collapse>
    </ValidationObserver>
    <!-- end: UW Approvals -->

    <ValidationProvider :rules="{ 'required': { allowFalse: false }}" name="uwApprovalsCompleted" v-slot="{ errors }">
      <input type="checkbox" hidden="hidden" class="form-control" v-model="uwApprovalsCompleted"/>
      <span class="text-danger">{{ errors[0] ? 'All Approvals must be completed before proceeding further.' : '' }}</span>
    </ValidationProvider>
    <br/>
    <ValidationProvider :rules="{ 'required': { allowFalse: false }}" name="uwApprovalsDataValid" v-slot="{ errors }">
      <input type="checkbox" hidden="hidden" class="form-control" v-model="appData.uwApprovalsDataValid"/>
      <span class="text-danger">{{ errors[0] ? 'Invalid approvals data - Please click on \'Refresh Approvals\' button to get the latest data.' : '' }}</span>
    </ValidationProvider>

    </div>
  </ValidationObserver>
</template>

<style lang="scss">

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import _ from "lodash";
import Swal from "sweetalert2";
import ApprovalAttachments from "@/view/pages/wizard/newbusiness-renewal/components/ApprovalAttachments.vue";

export default {
  name: "PremiumPage",
  components: { ApprovalAttachments },
  props: {
    appData: Object,
    vehicleTypes: Array,
    expandAllSections: Boolean,
    isReadOnly: Boolean
  },

  mounted() {
    if(this.expandAllSections) {
      this.isClientBusinessDetailsPanelExpanded = true;
      this.isCurrentPolicyDetailsPanelExpanded = true;
      this.isPanel3Expanded = true;
      this.isPanel4Expanded = true;
      this.isPanel5Expanded = true;
      this.isPanel6Expanded = true;
      this.isPanel7Expanded = true;
      this.isRatingQuestionsPanelExpanded = true;
      this.isRatingOutputPanelExpanded = true;
      this.isApprovalsPanelExpanded = true;
    }
  },

  data() {
    return {
      isClientBusinessDetailsPanelExpanded: false,
      isCurrentPolicyDetailsPanelExpanded: false,
      isPanel3Expanded: false,
      isPanel4Expanded: false,
      isPanel5Expanded: false,
      isPanel6Expanded: false,
      isPanel7Expanded: false,
      isRatingQuestionsPanelExpanded: false,
      isRatingOutputPanelExpanded: false,
      isApprovalsPanelExpanded: false,

      uwPremiumOverride: false,
      approvalBeingActioned: null,
      rejectionApprovalModalError: null,

      currencyConfig: {
        allowNegative: false,
        distractionFree: false,
        precision: 2
      }
    };
  },

  computed: {
    isRenewalTransaction: function() {
      const txnType = _.get(this.appData, 'transaction.K_Type');
      return txnType === 'Renewal';
    },

    isBrandFuse: function () {
      const brand = _.get(this.appData, 'transaction.K_Brand');
      return brand === 'Fuse';
    },

    atleast1BusinessActivitySelected: function() {
      if(this.appData) {
        return this.appData.transaction.K_BusinessActivitiesJson.length > 0;
      }
      return false;
    },

    fleetLookupRunning: function () {
      return _.get(this.appData, 'form.K_FleetLookupRunning') || false;
    },

    totalVehicleUsePercent: function() {
      let total = 0;
      if(this.appData) {
        for (const key in this.appData.transaction.K_VehicleUseJson) {
          total += parseFloat(this.appData.transaction.K_VehicleUseJson[key]) || 0;
        }
      }
      return total;
    },

    atleast1VehicleTypeAdded: function (){
      if(this.appData) {
        return this.appData.transaction.K_VehiclesSummary.length > 0;
      }
      return false;
    },

    atleast1PostcodeAdded: function (){
      if(this.appData) {
        return this.appData.transaction.K_PostcodeDistributionJson.length > 0;
      }
      return false;
    },

    showZeroCommissionWarning: function () {
      if(!_.isNil(this.appData.transaction.K_TotalCommissionPercent) && this.appData.transaction.K_TotalCommissionPercent) {
        return Number(this.appData.transaction.K_TotalCommissionPercent) === 0;
      }
      return false;
    },

    showBrokerCommissionError: function () {
      const totalCommissionPercent = !_.isNil(this.appData.transaction.K_TotalCommissionPercent) ? Number(this.appData.transaction.K_TotalCommissionPercent) : 0;
      const brokerCommissionPercent = !_.isNil(this.appData.transaction.K_BrokerCommissionPercent) ? Number(this.appData.transaction.K_BrokerCommissionPercent) : 0;
      return brokerCommissionPercent > totalCommissionPercent;
    },

    isMethodGranularPostcode: function() {
      if(this.appData) {
        return this.appData.transaction.K_VehicleInputMethod === 'Granular Postcode Information';
      }
      return false;
    },

    allowedExcesses: function () {
      const formatter = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });

      const minExcess = 1200;
      const excesses = [{ name: '', value: '' }];
      for(let i=minExcess; i<=50000; i+=100) {
        excesses.push({ name: formatter.format(i), value: String(i)});
      }
      return excesses;
    },

    allowedHistoricalExcesses: function () {
      const formatter = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });

      let minExcess = 1200;
      if(this.isBrandFuse) {
        minExcess = 1000;
      }
      const excesses = [{ name: '', value: '' }];
      for(let i=minExcess; i<=50000; i+=100) {
        excesses.push({ name: formatter.format(i), value: String(i)});
      }
      return excesses;
    },

    uwApprovalsCompleted: function() {
      if(this.appData) {
        const approvals = this.appData.uwApprovals;
        if(Array.isArray(approvals) && approvals.length > 0) {
          return !_.some(approvals, approval => {
            return approval.K_ApprovalStatus !== 'Approved';
          })
        }
        else {
          return true;
        }
      }
      return false;
    },

    experienceRating: function () {
      const expRating = this.appData?.transaction?.K_ExperienceRatingOutputJson || {};

      // Convert all frequencies to 2 decimals (as per David's request on 21/03/2024)
      for(const key in expRating) {
        if(key.includes('Freq')) {
          const value = expRating[key];
          if(_.isNumber(value)) {
            expRating[key] = Number(value).toFixed(2);
          }
        }
      }
      return expRating;
    }
  },

  methods: {
    addBusinessActivity: function () {
      this.appData.transaction.K_BusinessActivitiesJson.push({});
      this.calculateBusinessActivityPercentTotal();
    },

    deleteBusinessActivity: function (index) {
      this.appData.transaction.K_BusinessActivitiesJson.splice(index, 1);
      this.calculateBusinessActivityPercentTotal();
    },

    deleteVehicleTypeRow: function(index) {
      if (this.appData && this.appData.transaction && Array.isArray(this.appData.transaction.K_VehiclesSummary)) {
        let vehiclesSummary = this.appData.transaction.K_VehiclesSummary;

        if (index < vehiclesSummary.length) {
          vehiclesSummary.splice(index, 1);
          this.appData.transaction.K_VehiclesSummary = vehiclesSummary;
        }
        this.calculateVehiclePremiumTotals();
      }
    },

    addVehicleTypeRow: function() {
      if (this.appData && this.appData.transaction) {
        let vehiclesSummary = this.appData.transaction.K_VehiclesSummary;
        if (!vehiclesSummary) {
          vehiclesSummary = [];
        }
        vehiclesSummary.push({});
        this.appData.transaction.K_VehiclesSummary = vehiclesSummary;
        this.calculateVehiclePremiumTotals();
      }
    },

    addPostcodeEntry: function () {
      this.appData.transaction.K_PostcodeDistributionJson.push({});
      this.calculatePostcodeDistributionTotal();
    },

    deletePostcodeEntry: function (index) {
      this.appData.transaction.K_PostcodeDistributionJson.splice(index, 1);
      this.calculatePostcodeDistributionTotal();
    },

    calculateVehiclePremiumTotals: function() {
      this.$emit('calculateVehiclePremiumTotals');
    },

    calculateStateSummaryTotals: function() {
      this.$emit('calculateStateSummaryTotals');
    },

    calculateBusinessActivityPercentTotal: function() {
      this.$emit('calculateBusinessActivityPercentTotal');
    },

    calculatePostcodeDistributionTotal: function () {
      this.$emit('calculatePostcodeDistributionTotal');
    },

    calculateNetIncurredTotals: function() {
      this.$emit('calculateNetIncurredTotals');
    },

    paymentMethodChanged: function () {
      if (this.appData && this.appData.transaction) {
        const paymentMethod = this.appData.transaction.K_PaymentMethod;

        if(paymentMethod === 'Monthly Premium') {
          this.$set(this.appData.transaction, 'K_EndorsementFee', null);
        }
        else if(paymentMethod === 'Annual Premium') {
          this.$set(this.appData.transaction, 'K_MonthlyFee', null);
        }
      }
    },

    calculateTotalFees: function () {
      this.$emit('calculateTotalFees', this.uwPremiumOverride);
    },

    calculatePremiumTotalsUwOverride: function() {
      if(this.uwPremiumOverride) {
        this.$emit('calculatePremiumTotalsUwOverride');
      }
    },

    calculatePremiums: async function() {
      // Validate rating input data
      let refs = ['clientBusinessDetails','requirementsOfPolicy','fleetClaimsHistory','vehicleTypesAndPostcodes', 'ratingQuestions'];
      let isRatingInputValid = true;
      for await(let ref of refs) {
        isRatingInputValid = isRatingInputValid && await this.$refs[ref].validate();
      }

      if(isRatingInputValid) {
        // Clear unnecessary data
        if(this.isMethodGranularPostcode) {
          // Postcode Distribution method - Clear the Postcode Distribution table
          this.appData.transaction.K_PostcodeDistributionJson.splice(0, this.appData.transaction.K_PostcodeDistributionJson.length);
          delete this.appData.transaction.pdTotal;
        }
        else {
          // Granular Postcode Information method - Clear the state and postcode from vehicles table
          this.appData.transaction.K_VehiclesSummary.forEach(veh => {
            this.$set(veh, 'K_State', null);
            this.$set(veh, 'K_Postcode', null);
          });
        }

        this.$emit('calculatePremiums');
      }
      else {
        this.$emit('handleError', 'Please complete all mandatory fields required for rating.');
      }
    },

    savePremiumPage: function() {
      this.$emit('savePremiumPage');
    },

    calculateLossRatioPremium: function () {
      let basePremiumLossRatio = null;
      if(this.appData && this.appData.transaction.K_ExperienceTechnicalPremium) {
        basePremiumLossRatio = ((this.appData.transaction.K_ExperienceTechnicalPremium / 100 * 68.5)*100/80);
        basePremiumLossRatio = this.round(basePremiumLossRatio);
      }
      this.appData.transaction.K_BasePremiumLossRatio = basePremiumLossRatio;
    },

    confirmFleetDataLoad: function () {
      Swal.fire({
        titleText: "Warning",
        html: `<p class="text-justify">This action will <b>clear all vehicle/postcode summary data</b>, and reload the data from the fleet declaration.
               You will also need to recalculate the premiums.<br/><br/>Are you sure you want to continue?</p>`,
        icon: "warning",
        allowOutsideClick: false,
        allowEnterKey: false,
        confirmButtonText: 'Yes',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false
      })
          .then(result => {
            if(result && result.isConfirmed) {
              this.$emit('getVehicleSummaryFromFleetDeclaration');
            }
          })

    },

    confirmUwOverride: function () {
      Swal.fire({
        titleText: "Confirm UW Premium Override",
        html: `<p class="text-justify">This action will <b>clear all state-charges and premiums</b>, and you will need to manually calculate and enter the values.<br/><br/> Are you sure you want to override premium values?</p>`,
        icon: "warning",
        allowOutsideClick: false,
        allowEnterKey: false,
        confirmButtonText: 'Yes',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false
      })
        .then(result => {
          if(result && result.isConfirmed) {
            // Set approvals-data as invalid so that manual refresh is needed
            this.appData.uwApprovalsDataValid = false;

            // Clear state charges if more than 1 state
            if(Array.isArray(this.appData.transaction.K_StateSummary) && this.appData.transaction.K_StateSummary.length > 1) {
              this.appData.transaction.K_StateSummary.forEach(state => {
                state.K_EslFsl = null;
                state.K_StampDuty = null;
                state.K_Gst = null;
                state.K_AnnualPremium = null;
              });
              this.calculateStateSummaryTotals();
            }

            // Clear premium values
            this.appData.transaction.K_BasePremium = null;
            this.appData.transaction.K_BasePremiumPerRisk = null;
            this.appData.transaction.K_EslFsl = null;
            this.appData.transaction.K_UwGst = null;
            this.appData.transaction.K_StampDuty = null;
            this.appData.transaction.K_BrokerCommission = null;
            this.appData.transaction.K_BrokerCommissionGst = null;
            this.appData.transaction.K_SubTotal = null;
            this.appData.transaction.K_TotalAnnualPremium = null;
            this.appData.transaction.K_NetPayableToRentsure = null;

            this.calculatePremiumTotalsUwOverride();

            // Set the flag
            this.uwPremiumOverride = true;
          }
        })
    },

    confirmUwOverrideCancel: function () {
      Swal.fire({
        titleText: "Cancel UW Premium Override",
        html: `<p class="text-justify">Are you sure you want to cancel the premium override?</p>`,
        icon: "warning",
        allowOutsideClick: false,
        allowEnterKey: false,
        confirmButtonText: 'Yes',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false
      })
          .then(result => {
            if(result && result.isConfirmed) {
              this.uwPremiumOverride = false;

              this.calculatePremiums();
            }
          })
    },

    refreshUwApprovals: function () {
      this.$emit('refreshUwApprovals');
    },

    confirmApprove: function (approval) {
      this.rejectionApprovalModalError = null;

      const tempApproval = _.cloneDeep(approval);
      tempApproval.selectedFiles = [];
      this.approvalBeingActioned = tempApproval;
      this.$refs["approveModal"].show();
    },

    confirmReject: function (approval) {
      this.rejectionApprovalModalError = null;

      const tempApproval = _.cloneDeep(approval);
      tempApproval.selectedFiles = [];
      this.approvalBeingActioned = tempApproval;
      this.$refs["rejectModal"].show();
    },

    approveApproval: function () {
      this.$emit('approveApproval', this.approvalBeingActioned);
    },

    rejectApproval: async function (bvModalEvent) {
      if(!this.approvalBeingActioned.K_Comments) {
        this.rejectionApprovalModalError = 'Comments are mandatory.';
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit('rejectApproval', this.approvalBeingActioned);
    },

    round: function (number) {
        if(!_.isNil(number)) {
            return Math.round(Number(number) * 100 + Number.EPSILON)/100;
        }
        return null;
    },

    getApprovalStatusVariant: function(approvalStatus) {
      let variant = 'info';
      switch (approvalStatus) {
        case 'Awaiting Approval': variant = 'warning'; break;
        case 'Approved': variant = 'success'; break;
        case 'Rejected': variant = 'danger'; break;
      }
      return variant;
    },

    isUserEligibleForApproval: function (approval) {
      const userAuthLevel = this.appData.user ? this.appData.user.authLevel : 0;

      let approvalLevel = 0;
      if(approval.K_Level) {
        if(approval.K_Level.startsWith('Level-1')) {
          approvalLevel = 1;
        }
        else if(approval.K_Level.startsWith('Level-2')) {
          approvalLevel = 2;
        }
        else if(approval.K_Level.startsWith('Level-3')) {
          approvalLevel = 3;
        }
        else if(approval.K_Level.startsWith('Level-4')) {
          approvalLevel = 3;
        }
      }
      return userAuthLevel >= approvalLevel;
    }
  }
};
</script>
